<template>
  <div class="d-flex">
    <div class="d-flex flex-wrap gap-1">
      <evalmee-chip
        v-for="event in allActiveEvents"
        :key="event.kind"
        :icon="event.icon"
        :color="event.color"
        small
        label
        outlined
        v-tooltip="eventTooltip(event)"
      >
        {{ event.acrossSeveralExercise ? "<" : "" }}
        {{ event.duration ? `${event.duration}` : event.count }}
      </evalmee-chip>
    </div>
    <slot
      name="appendIfAny"
      v-if="allActiveEvents.length > 0"
    />

    <v-menu
      open-on-hover
      top
      offset-y
      nudge-top="10"
      close-delay="100"
      :disabled="!exerciseHasBeenSeen"
      v-if="exerciseEventsSummary"
    >
      <template #activator="{ on, attrs }">
        <evalmee-chip
          :icon="seenChipIcon"
          color="blue-grey"
          small
          label
          outlined
          v-bind="attrs"
          v-on="on"
        >
          <template v-if="showSeenChip">
            {{
              exerciseHasBeenSeen ?
                $t("quiz.editor.questionCoefficients.page.seen") :
                $t("quiz.editor.questionCoefficients.page.notSeen")
            }}
          </template>
          <evalmee-chip-divider
            class="mx-2"
            v-if="exerciseHasBeenSeen && showSeenChip"
          />
          <template v-if="exerciseHasBeenSeen">
            {{ exerciseDuration }}
          </template>
        </evalmee-chip>
      </template>

      <v-card>
        <v-card-text class="text-subtitle-2 pb-0">
          {{ $tc("quiz.editor.questionCoefficients.page.seenOccurrences", exerciseSeeEventsPairs.length, { count: exerciseSeeEventsPairs.length }) }}
        </v-card-text>
        <v-list
          dense
          class="very-dense"
        >
          <v-list-item
            v-for="(exerciseSeenEventPair, index) in exerciseSeeEventsPairs"
            :key="exerciseSeenEventPair.exercise_ids.join('-') + index"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ formatDateRange(exerciseSeenEventPair.start_date, exerciseSeenEventPair.end_date) }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action-text class="ml-4">
              {{ formatDuration(exerciseSeenEventPair.duration) }}
            </v-list-item-action-text>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import EvalmeeChip from "@/components/shared/evalmee_chip.vue"
import { eventDescription, eventName, eventKindsFor } from "@/composables/useStudentEvents"
import timeHelpers, { defaultTimeFormatWithSeconds } from "@/helpers/time_helpers"
import EvalmeeChipDivider from "@/components/shared/evalmee_chip_divider.vue"

export default {
  name: "ExerciseEventsSummary",
  components: { EvalmeeChipDivider, EvalmeeChip },
  props: {
    events: {
      type: Array,
      required: true,
    },
    eventsPairs: {
      type: Array,
      required: true,
    },
    copyOrCutEventsFromSubjectIds: {
      type: Array,
      required: true,
    },
    pasteEventsWithoutCopyIds: {
      type: Array,
      required: true,
    },
    copyOrCutEventsWithoutPasteIds: {
      type: Array,
      required: true,
    },
    exerciseEventsSummary: {
      type: Object,
      required: true,
    },
    showSeenChip: {
      type: Boolean,
      default: true,
    },
    quiz: {
      type: Object,
      required: true,
    },
  },
  computed: {
    displayableEventsPairs() {
      // count event pairs by kind
      const eventPairsCount = this.eventsPairs.reduce((acc, pair) => {
        acc[pair.kind] = (acc[pair.kind] || 0) + 1
        return acc
      }, {})

      // total duration by kind
      const eventPairsDuration = this.eventsPairs.reduce((acc, pair) => {
        acc[pair.kind] = (acc[pair.kind] || 0) + pair.duration
        return acc
      }, {})

      const eventPairsAcrossSeveralExercise = this.eventsPairs.reduce((acc, pair) => {
        acc[pair.kind] = acc[pair.kind] || pair.exercise_ids?.length > 1
        return acc
      }, {})

      const existingEventKinds = new Set(this.eventsPairs.map(pair => pair.kind))
      const visibleEventKinds = eventKindsFor(this.quiz)

      return Object.entries(visibleEventKinds)
        .filter(([kind, config]) =>
          existingEventKinds.has(kind) &&
          config.type === "eventPair" &&
          !config.debugOnly
        )
        .map(([kind, config]) => ({
          kind,
          icon: config.icon,
          color: config.color,
          label: eventName(kind, eventPairsCount[kind]),
          description: eventDescription(kind),
          count: eventPairsCount[kind],
          acrossSeveralExercise: eventPairsAcrossSeveralExercise[kind],
          duration: timeHelpers.formatDuration(eventPairsDuration[kind]),
        }))
    },
    displayableEvents() {
      const filteredEvents = this.events
        .filter(event => {
          if ((event.kind === "copy" || event.kind === "cut") && !(this.copyOrCutEventsFromSubjectIds.includes(event.id) || this.copyOrCutEventsWithoutPasteIds.includes(event.id))) return false
          return !(event.kind === "paste" && !this.pasteEventsWithoutCopyIds.includes(event.id))
        })

      // Count events by kind
      const eventCounts = filteredEvents.reduce((acc, event) => {
        acc[event.kind] = (acc[event.kind] || 0) + 1
        return acc
      }, {})

      const existingEventKinds = new Set(filteredEvents.map(event => event.kind))
      const visibleEventKinds = eventKindsFor(this.quiz)

      return Object.entries(visibleEventKinds)
        .filter(([kind, config]) =>
          existingEventKinds.has(kind) &&
          config.type === "event" &&
          !config.debugOnly
        )
        .map(([kind, config]) => ({
          kind,
          icon: config.icon,
          color: config.color,
          label: eventName(kind, eventCounts[kind]),
          description: eventDescription(kind),
          count: eventCounts[kind],
        }))
    },
    allActiveEvents() {
      return [...this.displayableEventsPairs, ...this.displayableEvents]
    },
    eventTooltip() {
      return (event) => {
        const onPage = event.acrossSeveralExercise ?
          this.$t("live.studentEvents.onSeveralPages") :
          this.$t("live.studentEvents.onThisPage")

        return `${event.duration ? `${event.duration} - ` : ""}${ event.count ? event.count : "" } ${event.label}
  ${onPage}. <br><br> ${event.description}.`

      }
    },
    exerciseHasBeenSeen() {
      return this.events.some(event => event.kind === "enter_exercise")
    },

    exerciseSeeEventsPairs() {
      return this.eventsPairs.filter(pair => pair.kind === "exercise_seen")
    },

    exerciseDuration() {
      return this.formatDuration(this.exerciseEventsSummary?.duration)
    },
    seenChipIcon() {
      if(!this.showSeenChip) return null
      return this.exerciseHasBeenSeen ? "mdi-eye-check-outline" : "mdi-eye-off-outline"
    },
  },
  methods: {
    formatDateRange(startDate, endDate) {
      return timeHelpers.formatDateRange(startDate, endDate, { showDateIfSameDay: false, timeFormat: defaultTimeFormatWithSeconds })
    },
    formatDuration(duration) {
      return timeHelpers.formatDuration(duration)
    },
  },
}
</script>

<style scoped>
.gap-1 {
  gap: 4px;
}
</style>
