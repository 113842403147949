<template>
  <v-footer
    v-if="visible"
    app
    outlined
    padless
    height="28"
    class="d-flex align-center status-bar text-body-2"
  >
    <div>
      {{ currentUser.email }}
    </div>
    <language-switch
      v-model="tempCurrentUser.language"
      @change="(language) => saveUser({ language, ...currentUser })"
      x-small
      tile
      class="d-inline-flex"
    />
    <v-menu
      offset-overflow
      offset-y
      nudge-bottom="-10px"
      top
      left
      :close-on-content-click="false"
    >
      <template #activator="{ on, attr }">
        <eva-button
          v-on="on"
          v-bind="attr"
          very-dense
          label="Beta features"
        />
      </template>

      <v-card max-width="400">
        <v-card-text>
          <beta-features-list
            :user="currentUser"
            @update:user="saveUser"
            :with-description="false"
            :allow-private-and-hidden="true"
          />
        </v-card-text>
      </v-card>
    </v-menu>

    <v-menu
      v-if="withStudentEvents"
      offset-overflow
      offset-y
      nudge-bottom="-10px"
      top
      left
      :close-on-content-click="false"
      :close-on-click="false"
      max-width="50vw"
      max-height="70vh"
      ref="studentEventsMenu"
    >
      <template #activator="{ on, attr }">
        <eva-button
          v-on="on"
          v-bind="attr"
          very-dense
          label="Student events"
        />
      </template>
      <v-card>
        <v-card-text>
          <v-simple-table dense>
            <template #default>
              <thead>
                <tr>
                  <th class="text-left">
                    Event
                  </th>
                  <th class="text-left">
                    Exercise
                  </th>
                  <th class="text-left">
                    Details
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(event, index) in lastStudentEvents"
                  :key="index"
                >
                  <td>{{ event.event }}</td>
                  <td>{{ event.exerciseId }}</td>
                  <td>
                    <code>
                      {{ event.details }}

                    </code>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-menu>

    <v-menu
      offset-overflow
      offset-y
      nudge-bottom="-10px"
      top
      left
      :close-on-content-click="false"
      :close-on-click="false"
    >
      <template #activator="{ on, attr }">
        <eva-button
          v-on="on"
          v-bind="attr"
          very-dense
        >
          <v-icon small>
            mdi-keyboard-settings-outline
          </v-icon>

          {{ keyboardLockState.lockedKeys.size }}
        </eva-button>
      </template>
      <v-card min-width="200">
        <v-card-text>
          <div v-if="keyboardLockState.lockedKeys.size">
            <div class="mb-2">
              Locked keys:
            </div>
            <div
              v-for="(key, index) in keyboardLockState.lockedKeys"
              :key="index"
              class="d-flex align-center mb-1"
            >
              <code class="mr-2">{{ key[0] }}</code>
            </div>
          </div>
          <div
            v-else
            class="text-caption"
          >
            No locked keys
          </div>
        </v-card-text>
      </v-card>
    </v-menu>

    <eva-button
      very-dense
      v-tooltip="'Hide names'"
      @click="blurNames = !blurNames"
    >
      <v-icon small>
        {{ blurNames ? 'mdi-ghost-off-outline' : 'mdi-ghost-outline' }}
      </v-icon>
    </eva-button>

    <v-menu
      offset-overflow
      offset-y
      nudge-bottom="-10px"
      top
      left
      :close-on-content-click="false"
    >
      <template #activator="{ on, attr }">
        <eva-button
          v-on="on"
          v-bind="attr"
          very-dense
        >
          <v-icon small>
            mdi-code-block-braces
          </v-icon>
        </eva-button>
      </template>
      <v-card>
        <v-card-text>
          <div
            v-for="param in routeParams"
            :key="`param-${param.key}`"
          >
            <b>{{ param.key }}:</b>
            <button
              @click="copyToClipboard(param.value)"
              v-tooltip="'Copy to clipboard'"
              class="ml-2"
            >
              <code>{{ param.value }}</code>
            </button>

            <button
              v-if="param.findMethod"
              @click="copyToClipboard(param.findMethod)"
              v-tooltip.top="'Copy to clipboard'"
              class="ml-2"
            >
              <div>
                <code>{{ param.findMethod }}</code>
              </div>
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-menu>

    <eva-button
      label="hide"
      very-dense
      @click="visible = false"
    />

    <status-bar-chart
      :value="fps"
      :low-value-threshold="30"
      class="py-1 ml-2"
      :width="60"
      :interval="1000"
      :max-history="20"
    />
    <span class="ml-1">
      {{ fps }}fps
    </span>
    <status-bar-chart
      :value="usedJSHeapSize"
      class="py-1 ml-2"
      :width="60"
      :interval="1000"
      :max-history="20"
    />
    <span
      v-tooltip="memoryTooltip"
      class="ml-1"
    >
      {{ size(usedJSHeapSize) }}MB
    </span>
    <v-icon
      small
      class="ml-2"
      v-if="!online"
    >
      mdi-wifi-off
    </v-icon>
  </v-footer>
</template>
<script>
import { mapActions, mapGetters } from "vuex"
import LanguageSwitch from "@/components/app/language_switch.vue"
import BetaFeaturesList from "@/views/user_profile/betaFeaturesList.vue"
import getFeatureMixin from "@/components/shared/get_feature_mixin"
import { useStudentEventRegisterer } from "@/composables/useRegisterStudentEvent"
import { useFps, useMemory, useOnline } from "@vueuse/core"
import StatusBarChart from "@/components/app/statusBar/statusBarChart.vue"
import { computed } from "vue"
import { blurNames } from "@/composables/useDevUtils"
import { newToast } from "@/helpers/notifications_helper"
import { useKeyboardLockState } from "@/composables/useKeyboardLock"

export default {
  name: "StatusBar",
  components: { StatusBarChart, BetaFeaturesList, LanguageSwitch },
  props: {
    withStudentEvents: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    visible: false,
  }),
  setup() {
    const { memory }  = useMemory()
    const usedJSHeapSize = computed(() => memory.value?.usedJSHeapSize || 0)
    const totalJSHeapSize = memory.value?.totalJSHeapSize || 0
    const jsHeapSizeLimit = memory.value?.jsHeapSizeLimit || 0

    function size(v) {
      const kb = v / 1024 / 1024
      return `${kb.toFixed(2)}`
    }

    const online = useOnline()
    const fps = useFps()

    const { state: keyboardLockState } = useKeyboardLockState()

    return {
      usedJSHeapSize,
      totalJSHeapSize,
      jsHeapSizeLimit,
      size,
      online,
      fps,
      blurNames: blurNames(),
      keyboardLockState,
    }

  },
  mixins: [getFeatureMixin],
  computed: {
    ...mapGetters(["currentUser"]),
    tempCurrentUser: {
      get() {
        return this.currentUser
      },
      set(value) {
        this.updateCurrentUser(value)
      },
    },
    lastStudentEvents() {
      return useStudentEventRegisterer().lastEvents.value.reverse()
    },
    memoryTooltip() {
      return `used: ${this.size(this.usedJSHeapSize)}MB <br>
              total: ${this.size(this.totalJSHeapSize)}MB <br>
              limit: ${this.size(this.jsHeapSizeLimit)}MB`
    },
    devEnv() {
      return process.env.NODE_ENV === "development"
    },
    /**
     * @returns {Array<{ key: string, value: string }>}
    */
    routeParams() {
      const params = Object.entries(this.$route.params).map(([key, value]) => ({ key, value }))

      params.forEach((param) => {
        if (param.key === "quizId") {
          param.findMethod = `Quiz.find(${param.value})`
        } else if (param.key === "scoreId") {
          param.findMethod = `Score.find(${param.value})`
        }
      })

      return params
    },
  },
  methods: {
    ...mapActions(["updateCurrentUser"]),
    saveUser(user) {
      this.updateCurrentUser(user)
    },
    copyToClipboard(val) {
      navigator.clipboard.writeText(val)
      newToast(`"${val}" copied to clipboard`)
    },
  },
  mounted() {
    this.visible = this.getFeature("admin_status_bar").value || this.devEnv
  },
  watch: {
    lastStudentEvents: {
      handler() {
        this.$refs.studentEventsMenu?.updateDimensions()
      },
      deep: true,
    },
  },
}
</script>


<style scoped>
.status-bar {
  padding: 0 12px;
  font-size: 10px !important;
  font-family: monospace !important;
}

.v-chip {
  height: 16px !important;
}
</style>
