export function useScroll() {

  const to = (ref, parentRef)  => {
    const el = ref
    const parentEl = parentRef

    if (el && parentEl) {
      const elRect = el.getBoundingClientRect()
      const parentRect = parentEl.getBoundingClientRect()

      parentEl.scrollBy({
        top: elRect.top - parentRect.top,
        left: elRect.left - parentRect.left,
        behavior: "smooth",
      })
    }
  }

  const intoView = (ref, { offset, behavior } = { offset: 0, behavior: "smooth" }) => {

    const el = ref

    if (el) {
      const elRect = el.getBoundingClientRect()
      const windowRect = document.body.getBoundingClientRect()

      window.scrollTo({
        top: elRect.top - windowRect.top - offset,
        behavior: behavior,
      })
    }


  }

  return {
    to,
    intoView,
  }
}
