<template>
  <v-app
    class="evalmee_v-app"
    id="evalmee-app"
    :key="currentUser.id"
  >
    <app-notifications no-app-version-update-notification />

    <router-view
      name="main"
      :mini-drawer.sync="miniDrawer"
    />

    <router-view
      name="nav"
      :is-fullscreen="fullscreen"
      @goFullscreen="goFullscreen"
      @toggleDrawer="$root.$emit('toggleDrawer')"
      :mini-drawer="miniDrawer"
      :permanent-drawer="permanentDrawer"
      :drawer-is-visible="drawerIsVisible"
    />

    <v-main class="evalmee-content">
      <v-container>
        <div class="container">
          <router-view
            :on-seb="onSeb"
            @goFullscreen="goFullscreen"
            :mini-drawer.sync="miniDrawer"
            @update:permanent-drawer="permanentDrawer = $event"
            @update:drawer-is-visible="drawerIsVisible = $event"
          />
        </div>
      </v-container>
    </v-main>

    <router-view
      name="camera"
      id="quiz-camera-preview"
    />

    <date-time-check />

    <status-bar with-student-events />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import ws from "../../web_sockets"
import AppNotifications from "../../components/shared/app_notifications.vue"
import fullscreenMixin from "./fullscreen_mixin"
import DateTimeCheck from "../../components/quiz/evalmee/date_time_check.vue"
import StatusBar from "@/components/app/statusBar.vue"

export default {
  name: "EvalmeeStudentContainer",
  components: { StatusBar, DateTimeCheck, AppNotifications },
  mixins: [fullscreenMixin],
  data: () => ({
    miniDrawer: false,
    showLeftDrawer: true,
    permanentDrawer: false,
    drawerIsVisible: true,
  }),
  methods: {
    ...mapActions(["fetchCurrentUser"]),
  },
  computed:{
    ...mapGetters(["currentUser"]),
    onSeb() {return this.$attrs.user_on_safe_browser_exam },
  },
  mounted() {
    this.fetchCurrentUser()
  },
  watch: {
    currentUser(user) {
      ws.channels.User.subscribe({ userId: user.id })
      if(user.id) return
      this.$router.push(
        { name: "userLogin", query: { redirect_to: window.location.href } })
    },
  },
}
</script>

<style scoped>
  .evalmee_v-app{
    background: #F8FBFF !important;
  }

  #quiz-camera-preview {
    position: fixed;
    bottom: 10px;
    right: 10px;
  }
</style>

