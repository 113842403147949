<template>
  <eva-comment-editor
    :content="content"
    @click:save="save"
    :label="$t('live.correction.privateFeedback.label')"
    :placeholder="$t('live.correction.privateFeedback.placeholder')"
    :save-label="$t('live.correction.privateFeedback.save')"
    icon="mdi-eye-off-outline"
  />
</template>

<script>
import EvaCommentEditor from "@/components/quiz/correction/EvaCommentEditor.vue"

export default {
  name: "PrivateCommentEditor",
  components: { EvaCommentEditor },
  props: {
    content: { type: String, required: false, default: "" },
  },
  emits: ["save"],
  methods: {
    save(comment) {
      this.$emit("save", comment)
    },
  },
  watch: {
    "content": {
      handler(val) {
        this.comment = val
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>
</style>
