<template>
  <span>
    <hr
      aria-orientation="vertical"
      :class="borderColor"
      class="v-divider--vertical"
      style="position: absolute;"
    >
  </span>
</template>

<script>
export default {
  name: "EvalmeeChipDivider",
  inject: ["borderColor"],
}
</script>
