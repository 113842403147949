<template>
  <div>
    <v-snackbar
      v-if="hasVersionChanged && !noAppVersionUpdateNotification"
      bottom
      text
      left
      color="primary"
      :value="hasVersionChanged"
      :timeout="-1"
      vertical
      :max-width="300"
      multi-line
    >
      {{ $t("notifications.newAppVersionAvailable.message") }}
      <template #action>
        <eva-button
          primary
          dense
          :label="$t('notifications.newAppVersionAvailable.action')"
          @click="windowReload"
        />
      </template>
    </v-snackbar>
    <v-snackbar
      v-for="(notification, index) in appNotifications.filter(n => n.show)"
      :key="notification.uuid"
      v-model="notification.show"
      :timeout="notification.timeout"
      top
      text
      right
      :color="colors[notification.type]"
      :style="`top: ${(index * 60) + 8}px`"
      :vertical="notification.action"
      :ref="`snackbar${index}`"
    >
      {{ notification.message }}

      <template #action="{ attrs }">
        <v-btn
          :color="colors[notification.type]"
          text
          v-bind="attrs"
          @click="notification.show = false"
          icon
          small
        >
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { useAppVersion } from "@/composables/useAppVersion"
import { mapGetters } from "vuex"

export default {
  name: "AppNotifications",
  props: {
    noAppVersionUpdateNotification: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { hasVersionChanged } = useAppVersion()
    return {
      hasVersionChanged,
    }
  },
  data() {
    return {
      snackbar: true,
      colors: {
        success: "success",
        error: "error",
        info: "info",
      },
    }
  },
  computed:{
    ...mapGetters(["appNotifications"]),
  },
  methods: {
    windowReload() {
      window.location.reload()
    },
  },
}
</script>

<style scoped>

</style>
