<template>
  <v-sheet
    @click.alt="debug = !debug"
    color="transparent"
  >
    <div
      v-if="!metSharingScreenRequirements"
      v-html="$t('live.examOnboarding.screenShare.instructions')"
    />

    <div v-if="debug && (screenCount() > 0 || sharedScreenSurface())">
      <div class="mt-4">
        Type de partage: {{ displaySurfaceText }}
      </div>
      <div>
        Nombre d'écrans: {{ screenCount() }}
      </div>
      <div>
        Nombre d'essais: {{ screenSharingAttempt }}
      </div>
    </div>
    <template v-if="errorMessage">
      <v-alert
        type="error"
        text
        dense
        icon="mdi-alert-outline"
        class="mt-4"
      >
        <span v-html="errorMessage" />
      </v-alert>


      <a
        href="https://evalmee.notion.site/Aide-partage-d-cran-18480d73d8c080d8a345c75aba84544d?pvs=4"
        target="_blank"
        v-if="screenSharingAttempt > 2"
      >{{ $t('live.examOnboarding.screenShare.buttons.help') }}</a>
    </template>

    <div class="mt-4 center">
      <eva-button
        v-if="showUpdateScreenShareButton"
        primary
        :label="$t('live.examOnboarding.screenShare.buttons.updateScreenShare')"
        @click="openScreenShareSettings"
      />

      <eva-button
        v-if="!sharedScreenSurface().value && !errorMessage"
        primary
        :label="$t('live.examOnboarding.screenShare.buttons.shareScreen')"
        @click="enableScreenShare"
      />
    </div>

    <div v-if="metSharingScreenRequirements">
      <template v-if="['Chrome', 'Chromium', 'Brave'].includes(browserInfo.browser_name)">
        {{ $t("live.examOnboarding.screenShare.hideMessage.chrome") }}
        <img
          src="@/assets/images/screen_sharing/chrome/sharing-popup-fr.png"
          height="auto"
          width="100%"
          class="my-4"
        >
      </template>
      <template v-else-if="browserInfo.browser_name === 'Firefox'">
        {{ $t("live.examOnboarding.screenShare.hideMessage.firefox") }}
        <img
          src="@/assets/images/screen_sharing/firefox/sharing-popup-fr.png"
          height="auto"
          width="100%"
          class="my-4"
        >
      </template>
      <template v-else>
        {{ $t("live.examOnboarding.screenShare.hideMessage.otherBrowser") }}
      </template>
    </div>
  </v-sheet>
</template>

<script>
import { useStudentEventRegisterer } from "@/composables/useRegisterStudentEvent"
import { useDeviceInfo } from "@/composables/useDeviceInfo"

export default {
  name: "QuizOnboardingScreenShare",
  props: {
    quiz: {
      type: Object,
      required: true,
    },
    quizzesAttemptSummary: {
      type: Object,
      required: true,
    },
  },
  emits: ["enable-screen-share", "open-screen-share-settings", "step-completed"],
  data() {
    return {
      debug: false,
      screenSharingAttempt: 0,
    }
  },
  setup(props) {
    const { screenCount, sharedScreenSurface, screenSharingError } = useStudentEventRegisterer(
      props.quiz?.id,
      props.quizzesAttemptSummary?.id
    )
    const { deviceDetails, browserInfo } = useDeviceInfo()

    return {
      screenCount,
      sharedScreenSurface,
      screenSharingError,
      deviceDetails,
      browserInfo,
    }
  },
  computed: {
    displaySurfaceText() {
      const surfaceType = this.sharedScreenSurface().value
      switch (surfaceType) {
      case "monitor":
        return "Screen"
      case "window":
        return "Window"
      case "browser":
        return "Browser tab"
      default:
        return "None"
      }
    },

    metSharingScreenRequirements() {
      return useStudentEventRegisterer(null, null).metSharingScreenRequirements.value
    },
    showUpdateScreenShareButton() {
      if(this.errorMessage) return true
      if(this.screenCount().value > 1 ) return true
      return this.sharedScreenSurface().value  && this.sharedScreenSurface().value !== "monitor"
    },
    errorMessage() {
      if (this.deviceDetails.device_type && this.deviceDetails.device_type !== "computer") {
        return this.$t("live.examOnboarding.screenShare.errors.deviceType")
      }
      if (this.screenCount().value > 1) {
        return this.$t("live.examOnboarding.screenShare.errors.screenCount")
      }
      if (this.sharedScreenSurface().value === "window-or-multiscreens" && this.screenCount().value > 0) {
        return this.$t("live.examOnboarding.screenShare.errors.screenTypeOrCount")
      }
      if (this.sharedScreenSurface().value !== "monitor" && this.screenCount().value > 0) {
        return this.$t("live.examOnboarding.screenShare.errors.screenType")
      }
      if (this.screenSharingError().value === "screen_share_system_denied") {
        return this.$t("live.examOnboarding.screenShare.errors.screenSharingSystemError", { browserName: this.browserInfo.browser_name })
      }
      if (this.screenSharingError().value === "screen_share_denied") {
        return this.$t("live.examOnboarding.screenShare.errors.screenSharingError")
      }
      return null
    },
  },
  methods: {
    enableScreenShare() {
      this.$root.$emit("enable-screen-share")
    },
    openScreenShareSettings() {
      this.screenSharingAttempt++
      this.$root.$emit("open-screen-share-settings")
    },
  },
  watch: {
    metSharingScreenRequirements: {
      handler(val) {
        console.log("metSharingScreenRequirements", val)
        if(val) {
          this.$emit("step-completed")
          this.screenSharingAttempt = 0
        }
      },
      immediate: true,
    },
  },
}
</script>
