import { useFullscreen } from "@vueuse/core"
import { createGlobalState } from "@vueuse/core"

export const useGlobalFullscreen = createGlobalState(() => {
  const { isFullscreen, enter, exit, toggle, isSupported } = useFullscreen()

  return {
    isFullscreen,
    enter,
    exit,
    toggle,
    isSupported,
  }
})
