import { ref } from "vue"
import { useEventListener } from "@vueuse/core"

const DEBOUNCE_CAPTURE_EVENT_DELAY = 100

const EXTENSIONS = [
  {
    name: "LanguageTool",
    tagPrefixes: ["lt-"],
    classPrefixes: ["lt-"],
    idPrefixes: ["lt-"],
  },
  {
    name: "Grammarly",
    tagPrefixes: ["grammarly-"],
    classPrefixes: ["grammarly-"],
    idPrefixes: ["grammarly-"],
  },
  {
    name: "DeepL",
    tagPrefixes: ["deepl-"],
    classPrefixes: ["deepl-"],
    idPrefixes: ["deepl-"],
  },
  {
    name: "Monica.ai",
    tagPrefixes: ["monica-"],
    classPrefixes: ["monica-"],
    idPrefixes: ["monica-"],
  },
  {
    name: "AITOPIA",
    tagPrefixes: ["aitopia-"],
    classPrefixes: ["aitopia-"],
    idPrefixes: ["aitopia-"],
  },
]

const IGNORED_TAGS = ["html", "div"]

export const useBrowserExtensionDetection = (options = {}) => {
  const lastInteraction = ref({
    target: null,
    timestamp: 0,
  })

  const isExtensionElement = (el) => {
    if (!el) return false

    // Check tag name
    if (el.tagName) {
      const tagName = el.tagName.toLowerCase()
      if (EXTENSIONS.some(ext =>
        ext.tagPrefixes.some(prefix => tagName.startsWith(prefix)))) {
        return true
      }
    }

    // Check classes
    if (el.classList) {
      const classes = Array.from(el.classList)
      if (EXTENSIONS.some(ext =>
        ext.classPrefixes.some(prefix =>
          classes.some(className => className.startsWith(prefix))))) {
        return true
      }
    }

    // Check id
    if (el.id) {
      if (EXTENSIONS.some(ext =>
        ext.idPrefixes.some(prefix => el.id.startsWith(prefix)))) {
        return true
      }
    }

    return false
  }

  const getExtensionType = (elements) => {
    // Check tag names first
    const tagMatch = EXTENSIONS.find(ext =>
      ext.tagPrefixes.some(prefix =>
        elements.some(el => el.tagName?.toLowerCase().startsWith(prefix))
      )
    )
    if (tagMatch) return tagMatch.name

    // Then check classes
    const classMatch = EXTENSIONS.find(ext =>
      ext.classPrefixes.some(prefix =>
        elements.some(el =>
          Array.from(el.classList || []).some(className =>
            className.startsWith(prefix)
          )
        )
      )
    )
    if (classMatch) return classMatch.name

    // Finally check ids
    const idMatch = EXTENSIONS.find(ext =>
      ext.idPrefixes.some(prefix =>
        elements.some(el => el.id?.startsWith(prefix))
      )
    )
    if (idMatch) return idMatch.name

    return "unknown"
  }

  const getElementText = (element) => {
    if (!element) return ""

    // Get direct text content (excluding child elements)
    const directText = Array.from(element.childNodes)
      .filter(node => node.nodeType === Node.TEXT_NODE)
      .map(node => node.textContent?.trim() || "")
      .join(" ")
      .trim()

    // If we have direct text, return it
    if (directText) return directText

    // Otherwise, get text from the first child that has text
    return element.innerText?.trim() || ""
  }

  const handleInteraction = (event) => {
    const target = event.target
    if (!target) return
    const targetTagName = target.tagName?.toLowerCase() || ""

    // Validate coordinates
    if (!Number.isFinite(event.clientX) || !Number.isFinite(event.clientY)) return

    // Check if this is a duplicate event on the same target within 100ms
    const now = Date.now()
    if (target === lastInteraction.value.target &&
        now - lastInteraction.value.timestamp < DEBOUNCE_CAPTURE_EVENT_DELAY) {
      return
    }

    // Update last interaction
    lastInteraction.value = {
      target,
      timestamp: now,
    }

    const elementAtPoint = document.elementFromPoint(event.clientX, event.clientY)
    const elementsFromPoint = document.elementsFromPoint(event.clientX, event.clientY)

    // Check if any element in the stack is from a browser extension
    const isFromExtension = elementsFromPoint.some(isExtensionElement)

    // Skip if it's in evalmee-app and not from an extension
    if (!isFromExtension && target.closest("#evalmee-app")) return
    if (!isFromExtension && IGNORED_TAGS.includes(targetTagName)) return

    const interactionData = {
      id: target.id || "",
      classes: Array.from(target.classList || []),
      text: getElementText(target),
      tagName: targetTagName,
      href: target.href || target.closest("a")?.href,
      event: event.type,
      coordinates: {
        x: event.clientX,
        y: event.clientY,
      },
      isFromExtension,
      extensionType: getExtensionType(elementsFromPoint),
      elementAtPoint: elementAtPoint ? {
        tagName: elementAtPoint.tagName?.toLowerCase(),
        classes: Array.from(elementAtPoint.classList || []),
      } : null,
      elementsFromPoint: elementsFromPoint.map(el => ({
        tagName: el.tagName?.toLowerCase() || "",
        classes: Array.from(el.classList || []),
        text: getElementText(el),
      })),
    }

    if (options.onInteraction) {
      options.onInteraction(interactionData)
    }

    return interactionData
  }

  // Add multiple event listeners for better interaction detection
  const events = [
    { type: "pointerdown", debounce: false }, // Covers mouse and touch
    { type: "mouseover", debounce: true },     // Debounced to reduce frequency
  ]

  let debounceTimer = null
  const debouncedHandler = (event) => {
    if (debounceTimer) return
    debounceTimer = setTimeout(() => {
      handleInteraction(event)
      debounceTimer = null
    }, DEBOUNCE_CAPTURE_EVENT_DELAY)
  }

  events.forEach(({ type, debounce }) => {
    useEventListener(window, type, debounce ? debouncedHandler : handleInteraction, {
      capture: true,
      passive: true,
    })
  })

  return {
    isExtensionElement,
    getExtensionType,
    EXTENSIONS,
    lastInteraction,
  }
}
