<template>
  <div class="cheat-modal" />
</template>

<script>
import eventsMixin from "./events_mixin"
import { computed, ref, toRefs, watch } from "vue"
import {
  useDocumentVisibility,
  useIdle,
  useIntervalFn,
  useTextSelection,
  useTimeoutFn,
  useTimestamp,
  useWakeLock,
  useWindowFocus,
  useWindowSize,
  watchDebounced
} from "@vueuse/core"
import { useStudentEventRegisterer } from "@/composables/useRegisterStudentEvent"
import { useMouseLeave } from "@/composables/events/useMouseLeave"
import { useClipboardEvent } from "@/composables/events/useClipboardEvent"
import { useDetectDevTool } from "@/composables/events/useDetectDevTool"
import { useCaptureConsole } from "@/composables/events/useCaptureConsole"
import { useBrowserExtensionDetection } from "@/composables/events/useBrowserExtensionDetection"
import { useGlobalFullscreen } from "@/composables/useGlobalFullscreen"

export default {
  name: "CheatModal2",
  props: {
    currentQuiz: { type: Object, required: true },
    currentExercise: { type: [Object, undefined], default: null },
    active: { type: Boolean, default: false },
    quizzesAttemptSummary: { type: Object, required: true },
  },
  mixins: [eventsMixin],
  setup(props) {
    const { currentExercise: currExercise } = toRefs(props)
    const currentExerciseId = computed(() => currExercise.value?.id)
    const eventRegisterer = useStudentEventRegisterer(props.currentQuiz.id, props.quizzesAttemptSummary.id)

    const { isOpen: devToolIsOpen } = useDetectDevTool()
    watch(devToolIsOpen, (newVal) => {
      eventRegisterer.register({
        event: newVal ? "dev_tool_open" : "dev_tool_close",
        exerciseId: currentExerciseId.value,
        details: {
          event_version: "2",
        },
      })
    })

    // Exercise change event
    watch(
      currExercise,
      (newVal, oldVal) => {
        if (!newVal) return
        if (oldVal) {
          eventRegisterer.register({
            event: "leave_exercise",
            exerciseId: oldVal.id,
          }).then(() => {
            eventRegisterer.register({
              event: "enter_exercise",
              exerciseId: newVal.id,
            })
          })
        } else {
          eventRegisterer.register({
            event: "enter_exercise",
            exerciseId: newVal.id,
          })
        }
      },
      { immediate: true },
    )

    // Mouse leave events
    const { hasLeft, x, y } = useMouseLeave()
    watch([x, y, hasLeft], ([newX, newY, newHasLeft], [prevX, prevY, prevHasLeft]) => {
      if (newHasLeft === prevHasLeft) return
      eventRegisterer.register({
        event: newHasLeft ? "mouse_leave" : "mouse_enter",
        exerciseId: currentExerciseId.value,
        details: {
          x: {
            from: prevX,
            to: newX,
          },
          y: {
            from: prevY,
            to: newY,
          },
          event_version: "2",
        },
      })
    } )


    // Fullscreen event
    const { isFullscreen } = useGlobalFullscreen()
    const isFullScreenInSplitScreen = computed(() => {
      if( window.screen.width !== window.innerWidth) return true
      // allow 10% difference for the screen height for Macbook Pro with notch
      return window.screen.height * 0.9 > window.innerHeight
    })
    const shouldInvertWidthAndHeight = () => {
      const { width, height,availWidth, availHeight } = window.screen

      if(width === availHeight && height === availWidth) return true
      if(width === availWidth && height === availHeight) return false

      return window.screen.orientation &&
        window.screen.orientation?.angle % 90 !== 0
    }
    const screenDetails = () => {

      const orientedWidth = shouldInvertWidthAndHeight() ? window.screen.height : window.screen.width
      const orientedHeight = shouldInvertWidthAndHeight() ? window.screen.width : window.screen.height

      return {
        width: window.screen.width,
        height: window.screen.height,
        oriented_width: orientedWidth,
        oriented_height: orientedHeight,
        available_height: window.screen.availHeight,
        available_width: window.screen.availWidth,
        inner_width: window.innerWidth,
        inner_height: window.innerHeight,
        outer_width: window.outerWidth,
        outer_height: window.outerHeight,
        screen_orientation: {
          angle: window.screen.orientation?.angle,
          type: window.screen.orientation?.type,
        },
        position: {
          screen_x: window.screenX,
          screen_y: window.screenY,
          screen_left: window.screenLeft,
          screen_top: window.screenTop,
        },
        browserUI: {
          personal_bar: window.personalBar?.visible,
          status_bar: window.statusBar?.visible,
          toolbar: window.toolbar?.visible,
        },
      }}

    watch(isFullscreen, (newVal) => {
      // console.log("isFullscreen changed")
      eventRegisterer.register({
        event: newVal ? "fullscreen_enter" : "fullscreen_leave",
        exerciseId: currentExerciseId.value,
        details: {
          event_version: "2",
          screen: screenDetails(),
        },
      })
    })

    // Window resize event
    const { width: windowWidth, height: windowHeight } = useWindowSize()
    const hasRecentlyResizedBrowser = ref(false)
    watchDebounced([windowWidth, windowHeight], ([newWidth, newHeight], [prevWidth, prevHeight]) => {

      // this is temporary disabled because it skip some events in fullscreen
      // if(isFullscreen.value && !isFullScreenInSplitScreen.value) return
      hasRecentlyResizedBrowser.value = true
      useTimeoutFn(() => {
        hasRecentlyResizedBrowser.value = false
      }, 1000)

      eventRegisterer.register({
        event: "resize_browser",
        exerciseId: currentExerciseId.value,
        details: {
          width: {
            from: prevWidth,
            to: newWidth,
          },
          height: {
            from: prevHeight,
            to: newHeight,
          },
          screen: screenDetails(),
          event_version: "2",
        },
      })
    }, { debounce: 100 })


    // Idle event
    // Register an event if the user is idle for more than 5 seconds
    const idleTimeout = 5000
    const { idle, lastActive } = useIdle(idleTimeout)
    const now = useTimestamp({ interval: 1000 })
    const idledFor = computed(() =>
      Math.floor((now.value - lastActive.value))
    )

    watch(idle, (newVal) => {
      eventRegisterer.register({
        event: newVal ? "become_idle" : "become_active",
        exerciseId: currentExerciseId.value,
        details: {
          idled_for: idledFor.value > 0 ? idledFor.value : 0,
        },
      })
    })


    // Visibility event
    // Register an event if the user switches tab or minimize the window
    const visibility = useDocumentVisibility()
    watch(visibility, (newVal) => {
      eventRegisterer.register({
        event: newVal === "hidden" ? "become_hidden" : "become_visible",
        exerciseId: currentExerciseId.value,
        details: {
          event_version: "2",
        },
      })
    })

    // Focus event
    // Register an event if the window gains or looses focus
    const focusedOnWindow = useWindowFocus()

    const focused = computed(() => {
      const isFocusedOnIframe = document.activeElement?.tagName === "IFRAME"
      return focusedOnWindow.value || isFocusedOnIframe
    })
    watch(focused, (newVal) => {

      eventRegisterer.register({
        event: (newVal) ? "get_focus" : "loose_focus",
        exerciseId: currentExerciseId.value,
        details: {
          event_version: "2",
          active_element: document.activeElement?.tagName,
        },
      })
    })


    // Text selection event
    // Register an event if the user selects text
    const { rects, text } = useTextSelection()
    watchDebounced(
      text,
      (newVal) => {
        if (!newVal) return
        if (newVal.length < 1) return

        eventRegisterer.register({
          event: "text_selection",
          exerciseId: currentExerciseId.value,
          details: {
            selection: newVal,
            rects: rects.value,
            event_version: "2",
          },
        })
      },
      { debounce: 1000 },
    )

    // Clipboard events
    const { copiedContent, cutContent, pastedContent } = useClipboardEvent()
    watch(copiedContent, (newVal) => {
      eventRegisterer.register({
        event: "copy",
        exerciseId: currentExerciseId.value,
        details: {
          selection: newVal,
          event_version: "2",
        },
      })
    })

    watch(cutContent, (newVal) => {
      eventRegisterer.register({
        event: "cut",
        exerciseId: currentExerciseId.value,
        details: {
          selection: newVal,
          event_version: "2",
        },
      })
    })

    watch(pastedContent, (newVal) => {
      eventRegisterer.register({
        event: "paste",
        exerciseId: currentExerciseId.value,
        details: {
          selection: newVal,
          event_version: "2",
        },
      })
    })

    // capture console.log console.info
    const consoleCatcher = (event) => {
      const { type, content } = event
      eventRegisterer.register({
        event: "console_log",
        exerciseId: currentExerciseId.value,
        details: {
          type,
          content,
          event_version: "2",
        },
      })
    }

    useCaptureConsole((type, ...args) => consoleCatcher( { type, content: args.join(" ") }))

    watch(
      [hasLeft, visibility, focused],
      ([mouseHasLeft, visibility, focused]) => {
        eventRegisterer.isOutsideOfExam().value = mouseHasLeft || visibility === "hidden" || !focused
      },
      { immediate: true },
    )

    const EXTENSION_SUSPECT_WINDOW = 1000 // 1 seconds

    const { start: startSuspectTimer } = useTimeoutFn(() => {
      eventRegisterer.hasRecentBrowserExtensionInteraction().value = false
    }, EXTENSION_SUSPECT_WINDOW)

    useBrowserExtensionDetection({
      onInteraction: (data) => {
        // console.log(data)
        eventRegisterer.hasRecentBrowserExtensionInteraction().value = true
        startSuspectTimer()
        eventRegisterer.register({
          event: "browser_extension_interaction",
          exerciseId: currentExerciseId.value,
          details: {
            event_version: "2",
            extension_name: data.extensionType,
            target: {
              id: data.id,
              tagName: data.elementAtPoint.tagName,
              classNames: data.elementAtPoint.classes || "",
              text: data.text,
              event: data.event,
            },
          },
        })
      },
    })

    watch(
      [isFullscreen, hasRecentlyResizedBrowser],
      ([isFullscreen, hasRecentlyResizedBrowser]) => {
        eventRegisterer.hasSuspectBehavior().value = (
          props.currentQuiz.require_fullscreen && (!isFullscreen || hasRecentlyResizedBrowser)
        )
      },
      { immediate: true }
    )



    // Wake lock
    const { isSupported, isActive: isWakeLockActive, request } = useWakeLock()

    // Request wake lock as soon as possible
    if (isSupported.value) { request() }

    // Re-request wake lock when document becomes visible
    watch([visibility, focused], ([visibility, focused]) => {
      if(!isSupported.value) return
      if(isWakeLockActive.value) return
      if (visibility === "visible" || focused) {
        request()
      }
    })


    // Device sleep detection
    const lastActiveTimestamp = ref(Date.now())
    const SLEEP_THRESHOLD_MS = 3000 // 3 seconds threshold to consider device was sleeping

    useIntervalFn(() => {
      // Skip if fullscreen is not required
      if (!props.currentQuiz.require_fullscreen) return

      const now = Date.now()
      const timeDiff = now - lastActiveTimestamp.value

      if (timeDiff > SLEEP_THRESHOLD_MS ) {
        //console.log("timeDiff", timeDiff, "lastActiveTimestamp", lastActiveTimestamp.value, "now", now)

        // Device just went to sleep
        eventRegisterer.register({
          event: "device_sleep",
          exerciseId: currentExerciseId.value,
          details: {
            event_version: "2",
          },
          happenedAt: new Date(lastActiveTimestamp.value),
        })

        // Register wake event with current timestamp
        eventRegisterer.register({
          event: "device_wake",
          exerciseId: currentExerciseId.value,
          details: {
            sleep_duration_ms: timeDiff,
            event_version: "2",
          },
          happenedAt: new Date(now),
        })
      }
      lastActiveTimestamp.value = now
    }, 1000)


    return { eventRegisterer, hasLeft, currentExerciseId, screenDetails, shouldInvertWidthAndHeight, hasRecentlyResizedBrowser, isFullscreen, visibility, focused }
  },
}
</script>
f
