<template>
  <div @click.alt="debug = !debug">
    <v-row class="mx-n3 mt-n4">
      <v-col
        v-for="(data, eventKind) in filteredEventReports"
        :key="`report-${eventKind}`"
        cols="12"
        md="auto"
        sm="6"
      >
        <quizzes-events-report-card
          :name="eventKind"
          :duration="data.total_duration"
          :count="data.occurrences"
          :icon="getIcon(eventKind)"
          :max="data.max_duration"
        />
      </v-col>
    </v-row>

    <v-card
      class="events-pairs-timeline"
      outlined
      flat
    >
      <EvalmeeQuizEventGenericTimeline
        :long-events="longEventsPrepared"
        :short-events="shortEventsPrepared"
        :line-events="lineEvents"
        :student-screenshots="studentScreenshots"
        :exercise-events-summaries="exerciseEventsSummaries"
        class="ma-2"
        @click:exercise="$emit('click:exercise', $event)"
      />
      <slot name="timelineAppend" />
    </v-card>
  </div>
</template>

<script>
import EvalmeeQuizEventGenericTimeline from "./evalmee_quiz_event_generic_timeline.vue"
import stringHelper from "../../helpers/string_helpers"
import QuizzesEventsReportCard from "./events-timeline/eventReportCard.vue"
import { eventIcons, eventPairColors, eventKindsFor } from "@/composables/useStudentEvents"

export default {
  name: "EvalmeeQuizEventsPairsTimeline",
  components: {
    QuizzesEventsReportCard,
    EvalmeeQuizEventGenericTimeline,
  },
  props: {
    eventsPairs: {
      type: Array,
      required: true,
    },
    events: {
      type: Array,
      required: true,
    },
    exercises: {
      type: Array,
      required: true,
    },
    eventsReports: {
      type: Object,
      required: true,
    },
    studentScreenshots: {
      type: Array,
      required: true,
    },
    exerciseEventsSummaries: {
      type: Array,
      required: true,
    },
    quiz: {
      type: Object,
      required: true,
    },
    copyOrCutEventsFromSubjectIds: {
      type: Array,
      required: true,
    },
    pasteEventsWithoutCopyIds: {
      type: Array,
      required: true,
    },
    copyOrCutEventsWithoutPasteIds: {
      type: Array,
      required: true,
    },
  },
  emits: ["click:exercise"],
  data: () => ({
    debug: false,
  }),
  computed: {
    eventsInReport() {
      const visibleEventKinds = eventKindsFor(this.quiz)
      return Object.entries(visibleEventKinds)
        .filter(([, config]) =>
          config.reportCard &&
          (!config.debugOnly || this.debug)
        )
        .map(([kind]) => kind)
    },

    filteredEvents() {
      return this.events.filter(event => {
        if (["copy", "cut"].includes(event.kind) && !this.debug) {
          return this.copyOrCutEventsFromSubjectIds.includes(event.id) || this.copyOrCutEventsWithoutPasteIds.includes(event.id)
        }
        if (event.kind === "paste" && !this.debug) {
          return this.pasteEventsWithoutCopyIds.includes(event.id)
        }
        return true
      })
    },

    longEventsPrepared() {
      const visibleEventKinds = eventKindsFor(this.quiz)
      const longEventKinds = Object.entries(visibleEventKinds)
        .filter(([, config]) => config.type === "eventPair" && (!config.debugOnly || this.debug))
        .map(([kind]) => kind)

      const eventKinds = longEventKinds.filter(kind => this.eventsPairs.some(eventPair => eventPair.kind === kind))

      return eventKinds.map(kind => {
        const eventsOfThisKind = this.eventsPairs.filter(eventPair => eventPair.kind === kind)
        return {
          kind: kind,
          color: this.getColor(kind),
          icon: this.getIcon(kind),
          name: this.$tc(`live.studentEvents.kind.${this.toCamelCase(kind)}.label`),
          events: eventsOfThisKind.map(event => ({
            start: new Date(event.start_date).getTime(),
            end: new Date(event.end_date).getTime(),
            exercises: event.exercise_ids?.map(id => ({
              id: id,
              name: this.exercises?.find(exercise => exercise.id === id)?.displayName,
            })),
            ...event,
          })),
        }
      })
    },
    shortEventsPrepared() {
      const visibleEventKinds = eventKindsFor(this.quiz)
      const shortEventKinds = Object.entries(visibleEventKinds)
        .filter(([, config]) => config.type === "event" && (!config.debugOnly || this.debug))
        .map(([kind]) => kind)

      const eventKinds = shortEventKinds.filter(kind => this.events.some(event => event.kind === kind))

      return eventKinds.map(kind => {
        const eventsOfThisKind = this.filteredEvents.filter(event => event.kind === kind)
        return {
          kind: kind,
          color: this.getColor(kind),
          icon: this.getIcon(kind),
          name: this.$tc(`live.studentEvents.kind.${this.toCamelCase(kind)}.label`),
          events: eventsOfThisKind.map(event => ({
            date: new Date(event.happened_at).getTime(),
            exercises: [{
              id: event.quizzes_exercise_id,
              name: this.exercises?.find(exercise => exercise.id === event.quizzes_exercise_id)?.displayName,
            }].filter(exercise => exercise.id),
            ...event,
          })),
        }
      })
    },
    lineEvents() {
      return this.events
        .filter(event => event.kind === "enter_exercise")
        .map(event => ({
          color: this.getColor(event.kind),
          date: new Date(event.happened_at).getTime(),
          exercise: this.exercises
            ?.find(exercise => exercise.id === event.quizzes_exercise_id),
          ...event,
        })
        )
    },

    filteredEventReports() {
      return Object.keys(this.eventsReports || {}).reduce((acc, key) => {
        if (this.eventsInReport.includes(key) && this.eventsReports[key].occurrences > 0) {
          acc[key] = this.eventsReports[key]
        }
        return acc
      }, {})
    },
  },
  methods: {
    getColor(kind) {
      return eventPairColors[kind]
    },

    getIcon(kind) {
      return eventIcons[kind]
    },
    toCamelCase(str) {
      return stringHelper.toCamelCase(str)
    },
  },
}
</script>

<style scoped>
.events-pairs-timeline {
  width: 100%;
}
</style>
