<template>
  <v-sheet
    tile
    flat
  >
    <slot
      name="title"
      :user="userName"
    />

    <v-list
      class="scrollable-messages"
      dense
    >
      <message-tips v-if="isGlobalChannel && channelMessages.length === 0 && channel">
        {{ $t("quiz.evalmeeChat.tips.globalChannelPlaceholder") }}
      </message-tips>
      <v-list-item-group ref="messages">
        <div
          v-for="(m, index) in channelMessages"
          :key="m.id"
        >
          <div
            class="caption ml-4 mt-4"
            :class="{ 'blur-names': blurNames}"
            v-if="m.user_id !== currentUser.id && !m.isGlobal && m.user_id !== (channelMessages[index - 1] && channelMessages[index - 1].user_id)"
          >
            {{ m.sendername }}
          </div>
          <v-list-item
            @click="handleClickOnMessage(m)"
            class="messages mt-1"
            :class="chooseCorrectMessageClass(m)"
            :ripple="false"
            :selectable="false"
          >
            <template>
              <v-list-item-content>
                <div
                  class="text--primary message-content"
                  v-text="m.content"
                />
                <message-send-info
                  :message="m"
                  :displayed-info-message="displayedInfoMessageId"
                />
              </v-list-item-content>
            </template>
          </v-list-item>
          <message-tips
            :key="m.id"
            v-if="m.isGlobal && index === channelMessages.length - 1"
            class="pb-4"
          >
            {{ $t("quiz.evalmeeChat.tips.groupMessage") }}
            <template v-if="m.user_id !== currentUser.id">
              {{ $t("quiz.evalmeeChat.tips.groupMessageAnswer") }}
            </template>
          </message-tips>
        </div>
      </v-list-item-group>
    </v-list>

    <v-textarea
      v-model="inputMessage"
      :placeholder="$t('quiz.evalmeeChat.inputMessage')"
      required
      solo
      filled
      flat
      auto-grow
      hide-details
      rows="1"
      @keydown.enter.prevent.exact="createMessage"
      class="ma-4"
      dense
    >
      <template #append>
        <div class="d-flex mt-n2">
          <v-btn
            @click="createMessage"
            icon
            color="primary"
          >
            <v-icon>mdi-send-outline</v-icon>
          </v-btn>
        </div>
      </template>
    </v-textarea>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import Vue from "vue"
import MessageTips from "./message-tips.vue"
import MessageSendInfo from "./messages_send_info.vue"
import subscriptionFacadeMixin from "../evalmee/subscription_facade_mixin"
import { blurNames } from "@/composables/useDevUtils"


export default {
  name: "ChatMessages",
  components: {
    MessageTips,
    MessageSendInfo },
  mixins: [subscriptionFacadeMixin],
  props: {
    quiz: {
      type: Object,
      default: () => ({}),
    },
    channelId: {
      type: Number,
      default: null,
    },
  },
  setup() {
    return {
      blurNames: blurNames(),
    }
  },
  data: () => {
    return {
      inputMessage: "",
      displayedInfoMessageId: null,
    }
  },

  computed: {
    ...mapGetters([
      "messages",
      "channelById",
      "currentUser",
      "globalChannelByQuizId",
    ]),
    channel() {
      return this.channelById(this.channelId)
    },
    globalChannel() {
      return this.globalChannelByQuizId(this.quiz.id)
    },
    userName() {
      const chan = this.channel
      return this.isGlobalChannel ? this.$t("quiz.evalmeeChat.globalChannel") : this.subscriptionsFacade.find((sub) => sub.user_id === chan.user_id)?.name
    },
    channelMessages() {
      // TODO: refactor this
      if (this.channelId !== this.globalChannel?.id) {
        return this
          .messages(this.channelId)
          .concat(this.messages(this.globalChannel?.id).map((message) => ({ ...message, isGlobal: true })))
          .sort((a, b) => {
            return new Date(a.created_at) - new Date(b.created_at)
          })}
      return this.messages(this.channelId)
    },
    isGlobalChannel() {
      return !this.channel?.user_id
    },
  },
  methods: {
    ...mapActions(["createMessageForChannel", "updateMessageOnChannel", "createChannelsForQuiz"]),
    createMessage() {
      if (this.channel) {
        this.createMessageForChannel({ channel: this.channel, userId: this.currentUser.id, content: this.inputMessage })
        this.inputMessage = ""
      }
      else {
        this.createChannel().then(channel => {
          this.createMessageForChannel({ channel: channel, userId: this.currentUser.id, content: this.inputMessage })
          this.inputMessage = ""
        })
      }
    },
    createChannel() {
      return this.createChannelsForQuiz({ quiz: this.quiz, userId: this.currentUser.id })
    },
    updateMessageStatus() {
      const allMessagesUnread = this.channelMessages.filter(m => (
        m.user_id !== this.currentUser.id &&
        m.status !== "read" &&
        this.channelById(m.quizzes_chat_channel_id)?.user_id
      ))
      allMessagesUnread.forEach(element => {
        this.updateMessageOnChannel({ message: element, status: "read" })
      })
    },
    chooseCorrectMessageClass(message) {
      if (message.isGlobal)
        return ("globalMessage")
      return message.user_id === this.currentUser.id ? "ownedMessage" : "otherMessage"
    },
    handleClickOnMessage(m) {
      this.displayedInfoMessageId = this.displayedInfoMessageId === m.id ? null : m.id
    },
    scrollToBottom() {
      if (this.$refs.messages?.$children?.length != 0)
        this.$refs.messages?.$children[this.$refs.messages.$children.length - 1].$el.scrollIntoView()
    },
  },
  watch: {
    "channelMessages.length": {
      handler() {
        this.updateMessageStatus()
        Vue.nextTick(() => {
          this.scrollToBottom()
        })
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style scoped>
  .messages {
    max-width: 70%;
    border-radius: 5px;
    margin: 1em;

  }
  .ownedMessage{
    margin-left: auto;
    margin-right: 1;
    background-color: #B6D6FB;
  }
  .otherMessage{
    background-color: #CFD8DC
  }
  .globalMessage{
    max-width: 100%;
    background-color: #e4e6e3;
  }
  .globalMessage .message-content {
    font-style: italic;
  }

  .scrollable-messages {
    overflow: auto;
    height: calc(100vh - 235px);
  }

  .max-growth{
    max-height: 100px;
    overflow: hidden;
  }
</style>
