import vueI18n from "@/plugins/i18n"
import stringHelpers from "@/helpers/string_helpers"

const useStudentEvents = function() {

  return {

  }
}

const eventColors = {
  mouse_leave: "blue",
  mouse_enter: "red",
  loose_focus: "green",
  get_focus: "purple",
  fullscreen_enter: "orange",
  fullscreen_leave: "pink",
  offline: "black",
  online: "grey",
  enter_exam: "brown",
  leave_exam: "cyan",
  enter_exercise: "magenta",
  leave_exercise: "yellow",
  browser_extension_interaction: "red",
}

const eventPairColors = {
  fullscreen_loss: "red",
  mouse_leave: "deep-orange",
  idle: "indigo",
  focus_loss: "deep-purple",
  window_hidden: "deep-purple",
  offline: "amber",
  online: "yellow",
  exam_sessions: "light-green",
  text_selection: "green",
  copy: "pink",
  paste: "cyan",
  cut: "brown",
  page_load: "grey",
  bulk_insert: "purple",
  fast_insert: "lime",
  resize_browser: "orange",
  enter_exercise: "lightblue",
  exam_left: "blue",
  dev_tool_open: "teal",
  enter_exam: "blue",
  console_log: "teal",
  answers_last_update: "blue",
  screen_share_loss: "brown",
  device_sleep: "brown",
  browser_extension_interaction: "purple",
}

const eventIcons = {
  fullscreen_loss: "mdi-fullscreen-exit",
  mouse_leave: "mdi-cursor-default-outline",
  idle: "mdi-sleep",
  focus_loss: "mdi-application-export",
  window_hidden: "mdi-tab-unselected",
  offline: "mdi-wifi-off",
  online: "mdi-wifi",
  exam_sessions: "mdi-ray-start-end",
  text_selection: "mdi-cursor-text",
  copy: "mdi-content-copy",
  paste: "mdi-content-paste",
  cut: "mdi-content-cut",
  page_load: "mdi-web",
  bulk_insert: "mdi-robot-outline",
  fast_insert: "mdi-keyboard-off-outline",
  resize_browser: "mdi-resize",
  enter_exercise: "mdi-book-open-page-variant",
  exam_left: "mdi-exit-run",
  dev_tool_open: "mdi-console",
  enter_exam: "mdi-ray-start-arrow",
  console_log: "mdi-console-line",
  answers_last_update: "mdi-text-box-edit-outline",
  screen_share_loss: "mdi-television-off",
  device_sleep: "mdi-power-sleep",
  browser_extension_interaction: "mdi-puzzle-outline",
}

const eventKinds = {
  fullscreen_loss: {
    icon: "mdi-fullscreen-exit",
    color: "red",
    type: "eventPair",
    debugOnly: false,
    visible: (quiz) => quiz?.require_fullscreen === true,
    reportCard: true,
  },
  focus_loss: {
    icon: "mdi-application-export",
    color: "deep-purple",
    type: "eventPair",
    debugOnly: false,
    visible: true,
    reportCard: true,
  },
  window_hidden: {
    icon: "mdi-tab-unselected",
    color: "deep-purple",
    type: "eventPair",
    debugOnly: false,
    visible: true,
    reportCard: true,
  },
  mouse_leave: {
    icon: "mdi-cursor-default-outline",
    color: "deep-orange",
    type: "eventPair",
    debugOnly: false,
    visible: true,
    reportCard: true,
  },
  device_sleep: {
    icon: "mdi-power-sleep",
    color: "brown",
    type: "eventPair",
    debugOnly: false,
    visible: (quiz) => quiz?.require_fullscreen === true,
    reportCard: false,
  },
  screen_share_loss: {
    icon: "mdi-television-off",
    color: "brown",
    type: "eventPair",
    debugOnly: false,
    visible: (quiz) => quiz?.screen_recording === true,
    reportCard: false,
  },
  idle: {
    icon: "mdi-sleep",
    color: "indigo",
    type: "eventPair",
    debugOnly: true,
    visible: true,
    reportCard: true,
  },
  offline: {
    icon: "mdi-wifi-off",
    color: "amber",
    type: "eventPair",
    debugOnly: false,
    visible: true,
    reportCard: true,
  },
  exam_sessions: {
    icon: "mdi-ray-start-end",
    color: "light-green",
    type: "eventPair",
    debugOnly: true,
    visible: true,
    reportCard: true,
  },
  exam_left: {
    icon: "mdi-exit-run",
    color: "blue",
    type: "eventPair",
    debugOnly: true,
    visible: true,
    reportCard: true,
  },
  // Event types below
  answers_last_update: {
    icon: "mdi-text-box-edit-outline",
    color: "blue",
    type: "event",
    debugOnly: false,
    visible: true,
    reportCard: false,
  },
  text_selection: {
    icon: "mdi-cursor-text",
    color: "green",
    type: "event",
    debugOnly: true,
    visible: true,
    reportCard: false,
  },
  copy: {
    icon: "mdi-content-copy",
    color: "pink",
    type: "event",
    debugOnly: false,
    visible: true,
    reportCard: false,
  },
  cut: {
    icon: "mdi-content-cut",
    color: "brown",
    type: "event",
    debugOnly: false,
    visible: true,
    reportCard: false,
  },
  paste: {
    icon: "mdi-content-paste",
    color: "cyan",
    type: "event",
    debugOnly: false,
    visible: true,
    reportCard: false,
  },
  bulk_insert: {
    icon: "mdi-robot-outline",
    color: "purple",
    type: "event",
    debugOnly: false,
    visible: true,
    reportCard: false,
  },
  fast_insert: {
    icon: "mdi-keyboard-off-outline",
    color: "lime",
    type: "event",
    debugOnly: false,
    visible: true,
    reportCard: false,
  },
  resize_browser: {
    icon: "mdi-resize",
    color: "orange",
    type: "event",
    debugOnly: false,
    visible: true,
    reportCard: false,
  },
  enter_exam: {
    icon: "mdi-ray-start-arrow",
    color: "blue",
    type: "event",
    debugOnly: false,
    visible: true,
    reportCard: false,
  },
  page_load: {
    icon: "mdi-web",
    color: "grey",
    type: "event",
    debugOnly: true,
    visible: true,
    reportCard: false,
  },
  dev_tool_open: {
    icon: "mdi-console",
    color: "teal",
    type: "event",
    debugOnly: true,
    visible: true,
    reportCard: false,
  },
  console_log: {
    icon: "mdi-console-line",
    color: "teal",
    type: "event",
    debugOnly: true,
    visible: true,
    reportCard: false,
  },
  browser_extension_interaction: {
    icon: "mdi-puzzle-outline",
    color: "purple",
    type: "event",
    debugOnly: true,
    visible: true,
    reportCard: false,
  },
}

const eventKindsFor = (quiz) => {
  return Object.fromEntries(
    Object.entries(eventKinds)
      .filter(([, config]) => {
        return typeof config.visible === "function" ? config.visible(quiz) : config.visible
      })
  )
}

const eventName = (kind, count = 1) => {
  const key =  stringHelpers.toCamelCase(kind)
  return vueI18n.tc(`live.studentEvents.kind.${key}.label`, count)
}

const eventDescription = (kind) => {
  const key =  stringHelpers.toCamelCase(kind)
  return vueI18n.t(`live.studentEvents.kind.${key}.description`)
}

export {
  useStudentEvents,
  eventColors,
  eventPairColors,
  eventIcons,
  eventKinds,
  eventKindsFor,
  eventName,
  eventDescription,
}
