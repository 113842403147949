
<template>
  <v-card
    flat
    outlined
  >
    <v-card-text>
      <div class="d-flex align-center">
        <v-icon
          v-html="icon"
          small
          class="mr-2"
          v-if="icon"
        />
        <b>

          {{ $tc(`live.studentEvents.kind.${toCamelCase(name)}.label`) }}
        </b>
      </div>
      <div class="number my-2">
        {{ formatDuration(duration) }}
      </div>

      <evalmee-chip
        small
        label
        outlined
        class="mr-2"
        v-if="max > 0"
        color="blue-grey"
      >
        {{ formatDuration(max) }} max
      </evalmee-chip>

      <span class="grey--text">
        {{ count }} {{ $t("live.studentEvents.occurrence") }}
      </span>
    </v-card-text>
  </v-card>
</template>

<script>
import timeHelpers from "../../../helpers/time_helpers"
import stringHelper from "../../../helpers/string_helpers"
import EvalmeeChip from "../../../components/shared/evalmee_chip.vue"

export default {
  name: "QuizzesEventsReportCard",
  components: { EvalmeeChip },
  props: {
    name: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: null,
    },
    duration: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
    count: {
      type: Number,
      default: 0,
    },

  },

  methods: {
    formatDuration(durationTimestamp) {
      return timeHelpers.formatDurationMs(durationTimestamp)
    },
    toCamelCase(str) {
      return stringHelper.toCamelCase(str)
    },
  },
}

</script>

<style scoped>
.number {
  color: #b5b5b5;
  font-weight: bold;
  font-size: 2.2em;
}

</style>
