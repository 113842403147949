import { mapGetters } from "vuex"

// Don't use this mixin anymore.
// User useQuizzesSubscription composable instead.
export default {
  computed: {
    ...mapGetters([
      "quizzesSubscriptions",
      "schoolsUser",
      "scoreByQuizAndUserId",
    ]),
    subscriptions() {
      const filter =
        this.quiz?.state === "preview"
          ? () => true
          : (s) => s.role === "student"
      return this.quizzesSubscriptions(this.quiz?.id).filter(filter)
    },
    scoreByUserId() {
      return (userId) => this.scoreByQuizAndUserId(this.quiz?.id, userId)
    },
    statusByUserId() {
      return (userId) => {
        if (!this.scoreByUserId(userId)) return "not_started"
        return this.scoreByUserId(userId).state
      }
    },
    subscriptionsFacade() {
      return this.subscriptions.map((subs) => {
        const schoolsUser = this.schoolsUser(subs.schools_user_id)
        const score = this.scoreByUserId(schoolsUser?.user_id)
        return {
          ...subs,
          name: subs?.name,
          lastName: schoolsUser?.last_name,
          email: schoolsUser?.email,
          teacher: schoolsUser?.teacher,
          cheating_attempt_count: score?.cheating_attempt_count,
          teacher_cheat_confirmation: score?.teacher_cheat_confirmation,
          answered_questions_count_cache: score?.answered_questions_count_cache,
          has_score: !!score,
          status: this.statusByUserId(schoolsUser?.user_id),
          user_id: subs?.user_id,
          student_id: schoolsUser?.student_id,
          score,
        }
      })
    },
  },
}
