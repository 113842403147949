<template>
  <div>
    <v-card
      v-if="menuOpen"
      outlined
    >
      <v-card-actions class="px-2 pt-2 pb-2">
        <eva-button
          v-if="canRollback"
          dense
          @click="rollback"
          v-tooltip="$t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.undo')"
        >
          <v-icon small>
            mdi-undo-variant
          </v-icon>
        </eva-button>
        <eva-button
          dense
          @click="generateFeedback"
          v-tooltip="$t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.regenerate')"
        >
          <v-icon small>
            mdi-refresh
          </v-icon>
        </eva-button>
        <template v-if="llmResponseLog">
          <v-spacer />
          <eva-button
            v-if="!rated"
            dense
            @click="rateSuggestion(-1)"
            v-tooltip="$t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.unhelpful')"
          >
            <v-icon small>
              mdi-thumb-down-outline
            </v-icon>
          </eva-button>
          <eva-button
            v-if="!rated"
            dense
            @click="rateSuggestion(1)"
            v-tooltip="$t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.helpful')"
          >
            <v-icon small>
              mdi-thumb-up-outline
            </v-icon>
          </eva-button>
        </template>
      </v-card-actions>
      <template v-if="rated && !userFeedbackSent">
        <v-divider class="pb-0" />
        <v-card-text

          class="pa-2"
        >
          <template>
            <v-textarea
              v-model="userFeedback"
              dense
              auto-grow
              solo
              filled
              flat
              hide-details
              class="mb-2"
              :label="rated < 0 ? $t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.userFeedback.unhelpful') : $t('evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.userFeedback.helpful')"
            />
            <div class="d-flex">
              <v-spacer />

              <eva-button
                dense
                primary
                @click="submitUserFeedback"
              >
                <v-icon
                  small
                >
                  mdi-send-outline
                </v-icon>
              </eva-button>
            </div>
          </template>
        </v-card-text>
      </template>
    </v-card>
  </div>
</template>
<script>

import { computed, ref, watch } from "vue"
import axios from "axios"
import { useStore } from "@/store"
import vueI18n from "@/plugins/i18n"


export default {
  name: "GenerateFeedbackMenu",
  components: {  },
  props: {
    answer: { type: Object, required: true },
  },
  emits: ["insert", "update-grade-suggestion", "replace", "generating"],
  setup(props, { emit }) {
    const showLoader = ref(false)
    const menuOpen = ref(false)
    const feedbackSuggestion = ref({})
    const llmResponseLog = ref(null)
    const rated = ref(null)
    const userFeedback = ref("")
    const userFeedbackSent = ref(false)
    const store = useStore()
    const previousFeedbackContent = ref("")

    const show = ()=> {
      menuOpen.value = true
    }

    const feedback = computed(() => {
      return store.getters.answersFeedbackByAnswerId(props.answer.id)
    })
    const generateFeedback = () => {
      showLoader.value = true
      llmResponseLog.value = null
      menuOpen.value = false
      emit("generating", true)
      store.dispatch("generateFeedbackForAnswer", { answer: props.answer })
        .then((returnedLlmResponseLogs) => {
          show()
          rated.value = null
          userFeedbackSent.value = false
          feedbackSuggestion.value = { ...feedback.value }
          llmResponseLog.value = returnedLlmResponseLogs[0]
          insert()
        })
        .catch((error) => {
          store.dispatch("newAppNotification", {
            message: vueI18n.t(
              "evalmee.home.teacher.openQuestionGrader.addComment.generateFeedback.generationError",
              { error }
            ),
            type: "error",
          })
        })
        .finally(() => {
          showLoader.value = false
          emit("generating", false)
        })
    }

    const rateSuggestion = (rate) => {
      if (!llmResponseLog.value) return
      axios
        .patch(llmResponseLog.value.links.self, { llm_response_log : { user_rating: rate } })
        .then(() => {
          rated.value = rate
        })
    }

    const submitUserFeedback = () => {
      if (!llmResponseLog.value) return
      axios
        .patch(llmResponseLog.value.links.self, { llm_response_log : { user_feedback: userFeedback.value } })
        .then(() => {
          rated.value = true
          userFeedback.value = ""
          userFeedbackSent.value = true
        })
    }

    const insert = () => {
      if (!feedbackSuggestion.value.generated_content) return
      previousFeedbackContent.value = `${feedbackSuggestion.value.user_content}`
      emit("replace", feedbackSuggestion.value.generated_content)
      // menuOpen.value = false
    }

    const rollback = () => {
      if (!previousFeedbackContent.value) return
      feedbackSuggestion.value.user_content = `${previousFeedbackContent.value}`
      previousFeedbackContent.value = ""
      emit("replace", feedbackSuggestion.value.user_content)
    }

    const canRollback = computed(() => {
      return !!previousFeedbackContent.value
    })

    const generatedContent = computed(() => feedbackSuggestion.value.generated_content)

    watch(generatedContent, (value) => {
      if (value) {
        show()
      }
    })

    return {
      showLoader,
      menuOpen,
      generateFeedback,
      feedbackSuggestion,
      insert,
      rateSuggestion,
      llmResponseLog,
      userFeedback,
      rated,
      submitUserFeedback,
      userFeedbackSent,
      show,
      rollback,
      canRollback,
      previousFeedbackContent,
    }
  },
}
</script>
