import { useStore } from "@/store"
import { computed } from "vue"
import { useQuizzesSubscription } from "@/composables/useQuizzesSubscription"
import { useSchoolsUser } from "@/composables/useSchoolsUser"
import i18n from "@/plugins/i18n"

export function useScore(score) {
  const store = useStore()

  const vmScoreDetailsText = computed(() => {
    const labels = [
      { range: [0, 0], label: i18n.t("evalmee.home.devices.vmScore.range.none") },
      { range: [1, 3], label: i18n.t("evalmee.home.devices.vmScore.range.low") },
      { range: [3, 6], label: i18n.t("evalmee.home.devices.vmScore.range.medium") },
      { range: [6, 10], label: i18n.t("evalmee.home.devices.vmScore.range.high") },
    ]

    const vmScore = score.max_virtual_machine_score

    return labels.find(label => vmScore >= label.range[0] && vmScore < label.range[1])?.label
  })

  const schoolsUser = computed(() => {
    return store.getters.schoolsUsers.find(su => su.user_id === score.user_id)
  })
  const subscription = computed(() => {
    return store.getters.quizzesSubscription(score.quiz_id, schoolsUser.value?.id)
  })

  const schoolsUserFacade = computed(() => {
    return useSchoolsUser(schoolsUser.value).facade()
  })

  const subscriptionFacade = computed(() => {
    return useQuizzesSubscription(subscription.value).facade()
  })

  const timeAccommodation = computed(() => {
    return store.getters.timeAccommodation(score.time_accommodation_id)
  })

  const session = computed(() => {
    return store.getters.quizSessions(score.quiz_id).find(s => s.id === score.quizzes_session_id)
  })

  function facade() {
    return {
      ...score,
      mouseExitDuration: score.cheat?.duration,
      devices: score.devices,
      schoolsUser: schoolsUser.value,
      subscription: subscription.value,
      timeAccommodation: timeAccommodation.value,
      success_levels_grade: score.grade,
      session: session.value,
      vmScoreDetailsText: vmScoreDetailsText.value,
    }
  }

  return {
    facade,
    schoolsUser,
    schoolsUserFacade,
    subscription,
    subscriptionFacade,
    timeAccommodation,
    vmScoreDetailsText,
  }
}
