<template>
  <div>
    <!--   This label should be linked to the text-field -->
    <label
      v-if="label"
      :class="{ 'body-1': bigLabel, 'black-text': bigLabel }"
    >{{ label }}</label>
    <v-text-field
      filled
      solo
      flat
      v-bind="$attrs"
      v-on="$listeners"
      dense
      :class="{ 'mt-2': bigLabel }"
      ref="text-field"
    >
      <slot
        v-for="slot in Object.keys($slots)"
        :name="slot"
        :slot="slot"
      />
    </v-text-field>
  </div>
</template>
<script>
export default {
  name: "EvaTextField",
  props: {
    label: {
      type: String,
      default: "",
    },
    bigLabel: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    validate() {
      return this.$refs["text-field"].validate()
    },
    blur() {
      this.$refs["text-field"].blur()
    },
  },
}
</script>

<style scoped>

</style>
