import axios from "axios"
import { useAppVersion } from "../composables/useAppVersion"

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  const appVersion = response.headers["x-app-version"]
  if (appVersion) {
    const { setLastVersion } = useAppVersion()
    setLastVersion(appVersion)
  }
  return response
}, function (error) {
  return Promise.reject(error)
})

export default axios
