import * as types from "../../mutation-types"
import axios from "axios"
import jsonApii from "../../../helpers/json_apii"
import VuexHelpers from "../../../helpers/vuex_helpers"

// initial state
const state = {
  subscriptions : [],
}

// getters
const getters = {
  quizzesSubscriptions: state => quizId => state.subscriptions.filter(s => s.quiz_id === quizId),
  quizzesStudentsSubscriptions: state => quizId => state.subscriptions.filter(s => s.quiz_id === quizId && s.role === "student"),
  quizzesSubscription: state => (quizId, schoolsUserId) => state.subscriptions.filter(s => s.quiz_id === quizId && s.schools_user_id === schoolsUserId)[0],
  quizzesOwnersLikeSubscriptions: state => quizId => state.subscriptions.filter(s => s.quiz_id === quizId && ["owner", "editor"].includes(s.role)),
  schoolsUserSubscriptions: state => schoolsUser => state.subscriptions.filter(s => s.schools_user_id === schoolsUser.id),
}

// actions
const actions = {
  fetchQuizzesSubscriptions({ commit }, { quiz, filter, pagination, sort, search } = { filter: {}, pagination: {}, sort: {}, search: {} }) {
    return axios
      .get(
        quiz.links.subscriptions_aggregations, {
          params: {
            filter,
            pagination,
            sort,
            search,
          },
          paramsSerializer: jsonApii.toFilters,
        })
      .then( response => {
        const subscriptions = jsonApii.getData(response.data)
        commit(types.UPDATE_QUIZZES_SUBSCRIPTIONS, subscriptions)
        commit(types.UPDATE_SCHOOL_USERS, jsonApii.getIncluded(response.data, "schools_user"))
        commit(types.UPDATE_QUIZZES_GROUPS, jsonApii.getIncluded(response.data, "quizzes_group"))
        jsonApii.getIncluded(response.data, "quizzes_accessibility_option").forEach( qao => {
          commit(types.CREATE_OR_UPDATE_ACCESSIBILITY_OPTION, qao)
        })
        jsonApii.getIncluded(response.data, "email").forEach( email => {
          commit(types.UPDATE_EMAIL, email)
        })
        const meta = jsonApii.getMeta(response.data)
        return {
          items: subscriptions,
          totalCount: meta.total,
        }
      })
  },
  createQuizzesSubscriptions({ commit }, { quiz, type, ...params }) {
    const validTypes = ["email", "name", "group", "file", "moodle", "schools_user", "aurion"]
    if (!validTypes.includes(type)) {
      throw new Error(`Invalid type: ${type}. Must be one of ${validTypes}`)
    }

    return axios
      .post(quiz.links.subscriptions, { type, ...params })
      .then( response => {
        const newSubscriptions = jsonApii.getData(response.data)
        commit(types.UPDATE_QUIZZES_SUBSCRIPTIONS, newSubscriptions)
        commit(types.UPDATE_SCHOOL_USERS, jsonApii.getIncluded(response.data, "schools_user"))
        commit(types.UPDATE_QUIZZES_GROUPS, jsonApii.getIncluded(response.data, "quizzes_group"))
        jsonApii.getIncluded(response.data,"time_accommodation_summary").forEach( ta => {
          commit(types.CREATE_OR_UPDATE_TIME_ACCOMMODATION_SUMMARY, ta, { root: true })
        })
        return newSubscriptions
      })
  },

  destroyQuizzesSubscriptions(context, { quiz, subscriptionIds }) {
    return axios
      .post(
        quiz.links.delete_batch_subscriptions,
        { ids: subscriptionIds }
      ).then( () => {
        subscriptionIds.forEach(id => context.commit(types.DELETE_QUIZZES_SUBSCRIPTION, id))
      })
  },

  // The following action should not be used yet.
  // Use "sendInstructionsToStudents" instead.
  sendQuizzesSubscriptionsInvitation(context, { quiz, subscriptionIds }) {
    return axios
      .post(
        quiz.links.send_invites,
        { ids: subscriptionIds }
      ).then( response => {
        context.commit(types.UPDATE_QUIZZES_SUBSCRIPTIONS, jsonApii.getData(response.data))
      })
  },

}

// mutations
const mutations = {
  [types.UPDATE_QUIZZES_SUBSCRIPTIONS](state, subscriptions) {
    subscriptions.forEach(
      subscription => VuexHelpers.createOrUpdate(state.subscriptions, subscription)
    )
  },
  [types.DELETE_QUIZZES_SUBSCRIPTION](state, subscriptionId) {
    state.subscriptions = state.subscriptions.filter(s => s.id !== subscriptionId)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
