<template>
  <v-chip
    v-bind="$attrs"
    v-on="$listeners"
    :x-small="xSmall"
    class="md-2 font-weight-bold px-2"
    :class="{
      'outlined-with-white-background': outlinedWithWhiteBackground,
    }"
    :color="parsedColor"
    :text-color="textColor"
    :outlined="parsedOutlined"
  >
    <v-icon
      left
      :x-small="xSmall"
      :small="!xSmall"
      :color="iconColor"
      v-if="colorDot"
    >
      mdi-checkbox-blank-circle
    </v-icon>
    <span
      class="icon-background"
      v-if="iconBackgroundCircle"
    />
    <v-icon
      left
      :x-small="xSmall"
      :small="!xSmall"
      :color="iconColor"
      :class="{
        'mr-n1': iconOnly,
        'ml-n1': iconOnly,
        'small-icon': true,
        'icon-only': iconOnly,
      }"
      v-if="icon"
    >
      {{ icon }}
    </v-icon>

    <div :class="{ 'ellipsis': ellipsis }">
      <slot v-if="!iconOnly" />
    </div>
  </v-chip>
</template>

<script>
export default {
  name: "EvalmeeChip",
  props: {
    outlined: { type: Boolean, default: false },
    icon: { type: String, default: null },
    color: { type: String, default: "grey" },
    colorDot: { type: Boolean, default: false },
    iconOnly: { type: Boolean, default: false },
    xSmall: { type: Boolean, default: false },
    ellipsis: { type: Boolean, default: false },
    faded: { type: Boolean, default: false },
    outlinedWithWhiteBackground: { type: Boolean, default: true },
    iconBackgroundCircle: { type: Boolean, default: false },
  },
  computed: {
    parsedColor() {
      if(!this.outlined) return `${this.color} lighten-5`

      return `${this.color} lighten-4`
    },
    parsedOutlined() {
      if(this.colorDot) return true

      return this.outlined
    },
    textColor() {
      if(this.faded) return `${this.color} darken-2`

      return `${this.color} darken-3`
    },
    iconColor() {
      if(this.faded) return `${this.color} lighten-1`

      return this.textColor
    },
  },
  provide() {
    return {
      color: this.color,
      borderColor: this.parsedColor,
    }
  },
}
</script>

<style scoped>
.ellipsis  {
  overflow: hidden;
  text-overflow: ellipsis;
}
.small-icon {
  font-size: 14px !important;
  line-height: 14px !important;
}

.small-icon:not(.icon-only) {
  margin-left: 0 !important;
}

.outlined-with-white-background.v-chip--outlined.v-chip.v-chip{
  background-color: white !important;
}

.icon-background::before {
    content: "";
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: currentColor;
    opacity: 0.15;
    font-size: 14px;
    text-align: center;
    line-height: 16px;
    display: block;
    position: absolute;
  vertical-align: middle;
  transform: translate(-2px, -50%);
}

.icon-background {
  vertical-align: middle;
  display: inline-block;
}
</style>
