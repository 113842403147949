import { createGlobalState, tryOnScopeDispose } from "@vueuse/core"
import { computed, reactive } from "vue"

const useKeyboardLockState = createGlobalState(() => {
  // Use reactive for the Map to ensure reactivity
  const state = reactive({
    // Map of key -> number of components requesting lock
    lockedKeys: new Map(),
    // Currently active locks in the browser
    activeLocks: new Set(),
  })

  return {
    state,
  }
})

export { useKeyboardLockState }

export function useKeyboardLock() {
  const { state } = useKeyboardLockState()

  /**
   * Request a lock for specific keys
   * @param {string[]} keys - Array of keys to lock
   * @returns {Promise<void>}
   */
  const lockKeys = async (keys) => {
    // Update lock count for each key
    keys.forEach(key => {
      const count = state.lockedKeys.get(key) || 0
      state.lockedKeys.set(key, count + 1)
    })

    // Get all keys that have at least one lock request
    const keysToLock = Array.from(state.lockedKeys.entries())
      .filter(([_, count]) => count > 0) //eslint-disable-line no-unused-vars
      .map(([key]) => key)

    if (keysToLock.length > 0 && navigator.keyboard?.lock) {
      try {
        await navigator.keyboard.lock(keysToLock)
        state.activeLocks = new Set(keysToLock)
      } catch (error) {
        console.warn("Could not lock keyboard:", error)
      }
    }
  }

  /**
   * Release lock for specific keys
   * @param {string[]} keys - Array of keys to unlock
   */
  const unlockKeys = (keys) => {
    // Update lock count for each key
    keys.forEach(key => {
      const count = state.lockedKeys.get(key) || 0
      if (count <= 1) {
        state.lockedKeys.delete(key)
      } else {
        state.lockedKeys.set(key, count - 1)
      }
    })

    // If no more locks are requested, unlock keyboard
    if (state.lockedKeys.size === 0 && navigator.keyboard?.unlock) {
      navigator.keyboard.unlock()
      state.activeLocks.clear()
    } else if (navigator.keyboard?.lock) {
      // Update locks with remaining locked keys
      const remainingKeys = Array.from(state.lockedKeys.keys())
      navigator.keyboard.lock(remainingKeys)
        .then(() => {
          state.activeLocks = new Set(remainingKeys)
        })
        .catch(error => console.warn("Could not update keyboard lock:", error))
    }
  }

  // Computed properties for checking lock status
  const isLocked = computed(() => state.activeLocks.size > 0)
  const getKeyLockCount = (key) => state.lockedKeys.get(key) || 0
  const isKeyLocked = (key) => state.activeLocks.has(key)

  // Cleanup on component unmount
  tryOnScopeDispose(() => {
    // Only unlock keys that this component locked
    if (state.lockedKeys.size > 0) {
      unlockKeys(Array.from(state.lockedKeys.keys()))
    }
  })

  return {
    lockKeys,
    unlockKeys,
    isLocked,
    getKeyLockCount,
    isKeyLocked,
    state,
  }
}
