import * as types from "../mutation-types"
import axios from "axios"
import api from "../../api"
import jsonApii from "../../helpers/json_apii"
import i18n from "./../../plugins/i18n"
import trackingHelpers from "../../helpers/tracking_helpers"
import errorMonitoringHelpers from "../../helpers/error_monitoring_helpers"
import chatSupportHelpers from "../../helpers/chat_support_helpers"

// initial state
const state = {
  currentUser : {},
}

// getters
const getters = {
  currentUser: state => state.currentUser,
}

// actions
const actions = {
  fetchCurrentUser({ commit, getters, rootGetters }) {
    return axios.get(api.v1.userSelfUrl())
      .then( responce => {
        const currentUser = jsonApii.getData(responce.data)
        const schoolsUsers = jsonApii.getIncluded(responce.data, "schools_user")
        const schools = jsonApii.getIncluded(responce.data, "school")
        const timeAccommodations = jsonApii.getIncluded(responce.data, "time_accommodation")

        commit(types.UPDATE_CURRENT_USER, currentUser)
        commit(types.UPDATE_SCHOOL_USERS, schoolsUsers)
        schools.forEach( school => {
          commit(types.UPDATE_SCHOOL, school)
        })
        timeAccommodations.forEach( timeAccommodation => {
          commit(types.CREATE_OR_UPDATE_TIME_ACCOMMODATION, timeAccommodation)
        })

        setupTrackers({
          user: getters.currentUser,
          currentSchool: rootGetters.currentSchool,
          schools: rootGetters.currentUserSchools,
        })

        return responce
      })
  },
  updateCurrentUser({ commit, getters, rootGetters }, user) {
    return axios.patch(user.links.self, { user })
      .then( response => {
        commit(types.UPDATE_CURRENT_USER, jsonApii.getData(response.data))

        setupTrackers({
          user: getters.currentUser,
          currentSchool: rootGetters.currentSchool,
          schools: rootGetters.currentUserSchools,
        })
      })
  },
}

// mutations
const mutations = {
  [types.UPDATE_CURRENT_USER](state, user){
    state.currentUser = user
    i18n.locale = user.language
  },
}

const setupTrackers =  ({ user, currentSchool, schools }) => {

  if (!user || !schools) return

  const payload = {
    user: user,
    currentSchool: currentSchool || schools[0],
    schools: schools,
  }

  chatSupportHelpers.updateCrispIndentification(payload)
  trackingHelpers.updateLogRocketIdentification(payload)
  trackingHelpers.updateScreebIdentification(payload)
  trackingHelpers.updateSegmentIdentification(user)
  trackingHelpers.updatePostHogIdentification(user)
  errorMonitoringHelpers.sentrySetUser(payload)
  trackingHelpers.updateCustomerIoIdentification(user)
  if (user.teacher) trackingHelpers.updateFeatureBaseIdentification(user.last_app_used,user )
}
window.fbi = trackingHelpers.updateFeatureBaseIdentification
export default {
  state,
  getters,
  actions,
  mutations,
}
