
<template>
  <v-card max-width="250">
    <div>
      <!--      <v-icon-->
      <!--        v-html="icon"-->
      <!--        class="mt-5 pl-4"-->
      <!--      />-->

      <div style="max-width: 100%">
        <v-card-title>
          {{ $tc(`live.studentEvents.kind.${toCamelCase(event.kind)}.label`) }}
        </v-card-title>
        <v-card-text v-if="content">
          <v-card
            flat
            outlined
          >
            <event-text-diff
              :diff="event?.details?.diff"
              v-if="hasDiff"
            />
            <div
              v-else
              class="ma-2"
            >
              {{ content }}
            </div>
          </v-card>
          <div
            v-if="event.kind === 'resize_browser'"
            class="mt-2"
          >
            <event-screen-size-chart
              :screen-width="event.details.screen.oriented_width || event.details.screen.width"
              :screen-height="event.details.screen.oriented_height || event.details.screen.height"
              :browser-width="event.details.width.to"
              :browser-height="event.details.height.to"
              :browser-position-x="event.details.screen.position.screen_x"
              :browser-position-y="event.details.screen.position.screen_y"
            />
            <div />
          </div>
        </v-card-text>

        <v-list
          dense
          class="very-dense"
          v-if="similarEventsByGroup.length > 0"
        >
          <v-subheader class="mx-2">
            {{ $t("live.studentEvents.similarEvents") }}
          </v-subheader>
          <template
            v-for="similarEventGroup in similarEventsByGroup"
          >
            <v-list-item
              v-for="similarEvent in similarEventGroup.events"
              :key="similarEvent.id"
            >
              <v-list-item-icon>
                <v-icon
                  small
                >
                  {{ similarEventGroup.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <katex-md
                    :expr="similarEvent.details?.content || similarEvent.details?.selection"
                    remove-all-html
                  />
                </v-list-item-title>
                <v-list-item-subtitle v-tooltip="$moment(similarEvent.date).format('llll:ss:SS')">
                  {{ $moment(similarEvent.date).format("LTS") }} - {{ similarEventGroup.name }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>

        <template v-if="event.exercises.length > 0">
          <v-divider />
          <v-list
            dense
            class="very-dense"
          >
            <v-list-item
              v-for="exercise in event.exercises.slice(0, allExercises ? undefined : 3)"
              :key="exercise.id"
            >
              <v-list-item-icon>
                <v-icon
                  small
                  v-html="'mdi-card-text-outline'"
                />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  <katex-md
                    :expr="exercise.name"
                    remove-all-html
                  />
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="event.exercises?.length > 3"
              @click="allExercises = !allExercises"
            >
              <v-list-item-icon>
                <v-icon
                  small
                  v-html="allExercises ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ allExercises ? $t("live.studentEvents.showLess") : $t("live.studentEvents.showAll") }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider />
        </template>
        <v-list
          dense
          class="very-dense"
        >
          <v-list-item
            v-for="item in timeItems.filter(i => i.visible)"
            :key="item.label"
            two-line
            v-tooltip="item.fullValue"
          >
            <v-list-item-icon>
              <v-icon small>
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.value }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </div>
    <v-card-text class="grey lighten-3 caption">
      <span v-html="$t(`live.studentEvents.kind.${toCamelCase(event.kind)}.description`)" />
    </v-card-text>
  </v-card>
</template>

<script>
import stringHelper from "../../../helpers/string_helpers"
import KatexMd from "../../../components/katex-md.vue"
import timeHelpers from "../../../helpers/time_helpers"
import EventTextDiff from "@/views/quizzes/events-timeline/eventTextDiff.vue"
import EventScreenSizeChart from "@/views/quizzes/events-timeline/eventScreenSizeChart.vue"

export default {
  name: "QuizzesEventsTimelineEventDetail",
  components: { EventScreenSizeChart, EventTextDiff, KatexMd },
  props: {
    event: {
      type: Object,
      required: true,
    },
    similarEventsByGroup: {
      type: Array,
      default: () => [],
    },
    icon: {
      type: String,
      default: "mdi-timer-outline",
    },
    tabId: {
      type: [String. Number],
      default: null,
    },
  },
  data: () => ({
    allExercises: false,
  }),
  computed: {
    timeItems() {
      return [
        {
          label: "Question",
          icon: "mdi-card-text-outline",
          value: this.event.details?.questionName,
          fullValue: this.event.details?.questionName,
          visible: this.event.details?.questionName,
        },
        {
          label: "Browser Window/Tab",
          icon: "mdi-tab",
          value: this.$t("live.studentEvents.window", { id: this.tabId }),
          visible: this.tabId && this.event.kind !== "answers_last_update",
        },
        {
          label: "Duration",
          icon: "mdi-timer-outline",
          value: this.formatDuration(this.event.duration),
          visible: this.event.duration,
        },
        {
          label: "Date",
          icon: "mdi-clock-outline",
          value: this.$moment(this.event.date).format("LTS"),
          fullValue: this.$moment(this.event.date).format("llll:ss:SS"),
          visible: this.event.date,
        },
        {
          label: "Start Date",
          icon: "mdi-clock-start",
          value: this.$moment(this.event.start_date).format("LTS"),
          fullValue: this.$moment(this.event.start_date).format("llll:ss:SS"),
          visible: this.event.start_date,
        },
        {
          label: "End Date",
          icon: "mdi-clock-end",
          value: this.$moment(this.event.end_date).format("LTS"),
          fullValue: this.$moment(this.event.end_date).format("llll:ss:SS"),
          visible: this.event.end_date,
        },
      ]
    },
    content() {
      if(this.event.kind === "resize_browser") {
        const details = this.event.details
        return `${details.width.from}x${details.height.from} -> ${details.width.to}x${details.height.to}`
      }
      if (this.event.kind === "browser_extension_interaction") {
        return `${this.event.details.extension_name} - ${this.event.details.target.text}`
      }

      return this.event.details?.content || this.event.details?.selection
    },
    hasDiff() {
      return this.event.details?.diff
    },

  },
  methods: {
    formatDuration(durationTimestamp) {
      return timeHelpers.formatDurationMs(durationTimestamp)
    },
    toCamelCase(str) {
      return stringHelper.toCamelCase(str)
    },
  },
}

</script>

<style scoped>

</style>
