import { ref, computed } from "vue"
import { createGlobalState } from "@vueuse/core"

export const useAppVersion = createGlobalState(() => {
  const initialVersion = ref(window.APP_VERSION || "1.0.0")
  const lastVersion = ref(window.APP_VERSION || "1.0.0")

  const setInitialVersion = (version) => {
    if (!initialVersion.value) {
      initialVersion.value = version
      lastVersion.value = version
    }
  }

  const setLastVersion = (version) => {
    lastVersion.value = version
  }

  const hasVersionChanged = computed(() => {
    return initialVersion.value && lastVersion.value && initialVersion.value !== lastVersion.value
  })

  return {
    initialVersion,
    lastVersion,
    setInitialVersion,
    setLastVersion,
    hasVersionChanged,
  }
})
