import { openDB } from "idb"
import ws from "../web_sockets"
import sha256 from "crypto-js/sha256"
import { DirectUpload } from "activestorage"
import api from "../api"

const eventStates = {
  CREATED: "created",
  SYNCHRONIZING: "synchronizing",
  SYNCHRONIZED: "synchronized",
}

const blobToArrayBuffer = async (file) => {
  return {
    data: await new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsArrayBuffer(file)
    }),
    name: file.name,
    type: file.type,
    lastModified: file.lastModified,
  }
}

const arrayBufferToFile = (storedData) => {
  const blob = new Blob([storedData.data], { type: storedData.type })
  return new File([blob], storedData.name, {
    type: storedData.type,
    lastModified: storedData.lastModified,
  })
}

export class EventStorage {

  constructor(quizId) {
    this.DB_NAME = "evalmee-fs"
    this.EVENTS_STORE_NAME = "flying-squirrel"
    this.SCREENSHOTS_STORE_NAME = "swimming-racoon"
    this.DB_VERSION = 2
    this.dbPromise = null
    this.quizId = quizId
  }

  async initializeIndexedDB() {
    const that = this
    if (!this.dbPromise) {
      this.dbPromise = openDB(this.DB_NAME, this.DB_VERSION, {
        upgrade(db) {
          if (!db.objectStoreNames.contains(that.EVENTS_STORE_NAME)) {
            const eventsStore = db.createObjectStore(that.EVENTS_STORE_NAME, { keyPath: "id", autoIncrement: true })
            eventsStore.createIndex("type", "type", { unique: false })
            eventsStore.createIndex("exerciseId", "exerciseId", { unique: false })
            eventsStore.createIndex("scoreId", "scoreId", { unique: false })
            eventsStore.createIndex("quizId", "quizId", { unique: false })
            eventsStore.createIndex("happenedAt", "happenedAt", { unique: false })
            eventsStore.createIndex("h", "h", { unique: false })
            eventsStore.createIndex("state", "state", { unique: false })
          }

          if (!db.objectStoreNames.contains(that.SCREENSHOTS_STORE_NAME)) {
            const screenshotsStore = db.createObjectStore(that.SCREENSHOTS_STORE_NAME, { keyPath: "id", autoIncrement: true })
            screenshotsStore.createIndex("exerciseId", "exerciseId", { unique: false })
            screenshotsStore.createIndex("scoreId", "scoreId", { unique: false })
            screenshotsStore.createIndex("quizId", "quizId", { unique: false })
            screenshotsStore.createIndex("happenedAt", "happenedAt", { unique: false })
            screenshotsStore.createIndex("h", "h", { unique: false })
            screenshotsStore.createIndex("state", "state", { unique: false })
          }
        },
      })
    }

    return this.dbPromise
  }

  async storeEvent({ type, exerciseId, scoreId, details, happenedAt } = { details: {}, happenedAt: new Date() }) {
    const event = {}
    event.kind = type
    event.details = details
    event.quizId = this.quizId
    event.exerciseId = exerciseId
    event.scoreId = scoreId
    event.happenedAt = happenedAt || new Date()
    event.state = eventStates["CREATED"]

    const db = await this.initializeIndexedDB()

    const tx = db.transaction(this.EVENTS_STORE_NAME, "readwrite")

    event.id = await tx.store.add({ ...event })
    event.h = this.eventHash(event)
    await tx.store.put(event)
    await tx.done

  }

  /**
   * Returns a sha256 hash of the event
   *
   * @param event
   * @return {string}
   */
  eventHash(event) {
    const attributes = {
      index: event.id,
      kind: event.kind,
      quiz_id: event.quizId,
      score_id: event.scoreId,
      exercise_id: event.exerciseId,
      happened_at: Math.floor(event.happenedAt / 1000),
    }

    const nonNullAttributes = Object.fromEntries(
      // eslint-disable-next-line no-unused-vars
      Object.entries(attributes).filter(([_, v]) => v != null)
    )

    return sha256(JSON.stringify(nonNullAttributes)).toString()
  }

  async deleteEvent(eventId) {
    const db = await this.initializeIndexedDB()

    return db.delete(this.EVENTS_STORE_NAME, eventId)
  }

  async saveEvent(event) {
    const db = await this.initializeIndexedDB()

    return db.put(this.EVENTS_STORE_NAME, event)
  }


  async getAllEvents() {
    const db = await this.initializeIndexedDB()

    return db.getAllFromIndex(this.EVENTS_STORE_NAME, "quizId", this.quizId)
  }

  //same as getAllEvents but with a callbac executed in the transaction
  async getAllEventsWithCallback(callback) {
    const db = await this.initializeIndexedDB()
    const tx = db.transaction(this.EVENTS_STORE_NAME, "readwrite")
    const store = tx.store
    const index = store.index("quizId")
    const events = await index.getAll(this.quizId)
    for (const event of events) {
      await callback(event)
    }
    await tx.done
  }

  // same function as syncAllEvents but all the action should be within a trnsaction
  async syncAllEvents() {
    const db = await this.initializeIndexedDB()

    // Sync events
    const eventsTx = db.transaction(this.EVENTS_STORE_NAME, "readwrite")
    const eventsStore = eventsTx.store
    const eventsIndex = eventsStore.index("quizId")
    const events = await eventsIndex.getAll(this.quizId)
    await eventsTx.done

    // Process events
    for (const event of events) {
      if (event.state === eventStates["SYNCHRONIZING"]) continue

      // Mark as synchronizing
      const markTx = db.transaction(this.EVENTS_STORE_NAME, "readwrite")
      event.state = eventStates["SYNCHRONIZING"]
      await markTx.store.put(event)
      await markTx.done

      const isSent = await this.syncEvent(event)

      // Update based on result
      const updateTx = db.transaction(this.EVENTS_STORE_NAME, "readwrite")
      if (isSent) {
        await updateTx.store.delete(event.id)
      } else {
        event.state = eventStates["CREATED"]
        await updateTx.store.put(event)
      }
      await updateTx.done
    }1

    // Get all screenshots that need syncing (not synchronized or synchronizing)
    const screenshotsTx = db.transaction(this.SCREENSHOTS_STORE_NAME, "readwrite")
    const screenshotsStore = screenshotsTx.store
    const screenshotsIndex = screenshotsStore.index("quizId")
    const screenshots = await screenshotsIndex.getAll(this.quizId)
    const screenshotsToSync = screenshots.filter(s => s.state === eventStates["CREATED"])
    await screenshotsTx.done

    // Process screenshots one by one
    for (const screenshot of screenshotsToSync) {
      try {
        // Start a new transaction for this screenshot
        const tx = db.transaction(this.SCREENSHOTS_STORE_NAME, "readwrite")
        const currentScreenshot = await tx.store.get(screenshot.id)

        // Skip if screenshot was already processed or is being processed
        if (!currentScreenshot || currentScreenshot.state !== eventStates["CREATED"]) {
          await tx.done
          continue
        }

        // Mark as synchronizing
        currentScreenshot.state = eventStates["SYNCHRONIZING"]
        await tx.store.put(currentScreenshot)
        await tx.done

        // Try to sync
        const isSent = await this.syncScreenshot(currentScreenshot)

        // Update final state
        const finalTx = db.transaction(this.SCREENSHOTS_STORE_NAME, "readwrite")
        if (isSent) {
          await finalTx.store.delete(currentScreenshot.id)
        } else {
          currentScreenshot.state = eventStates["CREATED"]
          await finalTx.store.put(currentScreenshot)
        }
        await finalTx.done
      } catch (error) {
        console.error("Error processing screenshot:", error)
        // Reset state on error
        const errorTx = db.transaction(this.SCREENSHOTS_STORE_NAME, "readwrite")
        screenshot.state = eventStates["CREATED"]
        await errorTx.store.put(screenshot)
        await errorTx.done
      }
    }
  }

  async syncEvent(event) {
    return ws.subscriptions?.QuizStudentEvents?.send({
      index: event.id,
      event: event.kind,
      exercise_id: event.exerciseId,
      score_id: event.scoreId,
      happened_at:event.happenedAt ,
      details: event.details,
      hash: event.h,
    })
  }

  async storeScreenshot({ scoreId, happenedAt, imageFile, imageSignedId }) {
    const screenshot = {
      kind: "screenshot",
      quizId: this.quizId,
      scoreId: scoreId,
      imageSignedId: imageSignedId,
      happenedAt: happenedAt || new Date(),
      state: eventStates["CREATED"],
    }

    // Convert imageFile to ArrayBuffer if it exists
    if (imageFile) {
      try {
        const storedData = await blobToArrayBuffer(imageFile)
        screenshot.imageFileData = storedData
      } catch (error) {
        console.error("Error converting image file to ArrayBuffer:", error)
        throw error
      }
    }

    const db = await this.initializeIndexedDB()
    const tx = db.transaction(this.SCREENSHOTS_STORE_NAME, "readwrite")

    screenshot.id = await tx.store.add({ ...screenshot })
    screenshot.h = this.eventHash(screenshot)
    await tx.store.put(screenshot)
    await tx.done
  }

  async getAllScreenshots() {
    const db = await this.initializeIndexedDB()
    return db.getAllFromIndex(this.SCREENSHOTS_STORE_NAME, "quizId", this.quizId)
  }

  async getUnsyncedEventsCount() {
    const db = await this.initializeIndexedDB()
    const tx = db.transaction(this.EVENTS_STORE_NAME, "readonly")
    const store = tx.store
    const index = store.index("quizId")
    const events = await index.getAll(this.quizId)
    await tx.done
    return events.filter(event => event.state !== eventStates["SYNCHRONIZED"]).length
  }

  async getUnsyncedScreenshotsCount() {
    const db = await this.initializeIndexedDB()
    const tx = db.transaction(this.SCREENSHOTS_STORE_NAME, "readonly")
    const store = tx.store
    const index = store.index("quizId")
    const screenshots = await index.getAll(this.quizId)
    await tx.done
    return screenshots.filter(screenshot => screenshot.state !== eventStates["SYNCHRONIZED"]).length
  }

  async syncScreenshot(screenshot) {
    try {
      if (screenshot.imageSignedId) {
        return await this.sendScreenshotEvent(screenshot, screenshot.imageSignedId)
      }

      // Convert ArrayBuffer back to File
      let imageFile
      if (screenshot.imageFileData) {
        imageFile = arrayBufferToFile(screenshot.imageFileData)
      } else {
        imageFile = screenshot.imageFile
      }

      if (!imageFile) {
        console.error("No image file or signed_id found for screenshot:", screenshot)
        return false
      }

      const upload = new DirectUpload(imageFile, api.activeStorageDirectUploadUrl())

      return new Promise((resolve) => {
        upload.create(async (error, blob) => {
          if (error) {
            console.error("Error uploading screenshot:", error)
            resolve(false)
            return
          }

          try {
            const sent = await this.sendScreenshotEvent(screenshot, blob.signed_id)
            resolve(sent)
          } catch (error) {
            console.error("Error sending screenshot event:", error)
            resolve(false)
          }
        })
      })
    } catch (error) {
      console.error("Error syncing screenshot:", error)
      return false
    }
  }

  async sendScreenshotEvent(screenshot, imageSignedId) {
    const sent = await ws.subscriptions?.QuizStudentEvents?.send({
      index: screenshot.id,
      event: "screenshot",
      image: imageSignedId,
      score_id: screenshot.scoreId,
      happened_at: screenshot.happenedAt,
      hash: screenshot.h,
    })
    return !!sent
  }

  async hasUnsyncedItems() {
    const db = await this.initializeIndexedDB()

    // Check events
    const eventsTx = db.transaction(this.EVENTS_STORE_NAME, "readonly")
    const eventsStore = eventsTx.store
    const eventsIndex = eventsStore.index("quizId")
    const events = await eventsIndex.getAll(this.quizId)
    await eventsTx.done

    // Check screenshots
    const screenshotsTx = db.transaction(this.SCREENSHOTS_STORE_NAME, "readonly")
    const screenshotsStore = screenshotsTx.store
    const screenshotsIndex = screenshotsStore.index("quizId")
    const screenshots = await screenshotsIndex.getAll(this.quizId)
    await screenshotsTx.done

    // Return true if there are any items in CREATED or SYNCHRONIZING state
    return events.some(event => event.state !== eventStates["SYNCHRONIZED"]) ||
           screenshots.some(screenshot => screenshot.state !== eventStates["SYNCHRONIZED"])
  }
}
