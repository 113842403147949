<template>
  <div>
    <div
      v-if="debug"
      style="position: absolute; top: 50px; left: 50px; z-index: 1000;"
    >
      <eva-button
        @click="startCapture"
        label="start capture"
      />

      <eva-button
        @click="startRecordingLoop"
        label="start recording"
      />

      <eva-button
        @click="stopCapture"
        label="stop capture"
      />

      <eva-button
        @click="syncEvents"
        label="sync events--"
      />

      Is recording: {{ enableRecording }} - enableScreenSharing: {{ enableScreenSharing }} -
      Surface: {{ displaySurface }} - Screens: {{ displayScreens }} -
      lastScreenshotImageDifferences: {{ lastScreenshotImageDifferences }}
    </div>


    <video
      id="video"
      autoplay
      ref="video"
      style="display: none;"
    />
  </div>
</template>

<script>
import api from "@/api"
import { EventStorage } from "@/helpers/studentEventStore"
import Compressor from "compressorjs"
import eventsMixin from "@/components/shared/events_mixin"
import html2canvas from "html2canvas"

const displayMediaOptions = {
  video: {
    cursor: "always",
    displaySurface: "monitor",
  },
  audio: false,
  selfBrowserSurface: "exclude",
  systemAudio: "exclude",
}

const RECORDING_INTERVAL_MS = 2000 // Take screenshot every 2 seconds
const RECORDING_INTERVAL_SLOW_MS = 15000 // Take screenshot every 15 seconds

export default {
  name: "QuizScreenRecord2",
  mixins: [eventsMixin],
  props: {
    enableScreenSharing: {
      type: Boolean,
      default: false,
    },
    enableRecording: {
      type: Boolean,
      default: false,
    },
    enableHtmlRecording: {
      type: Boolean,
      default: false,
    },
    enableOverlayDetection: {
      type: Boolean,
      default: false,
    },
    quizzesAttemptSummary: {
      type: Object,
      default: () => ({}),
    },
    lowDelayBetweenFrames: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      debug: false,
      stream: null,
      recordingInterval: null,
      overlayDetectionInterval: null,
      displaySurface: null,
      displayScreens: 0,
      screenChangeListener: null,
      lastOverlayCheck: 0,
      overlayCheckThrottleMs: 1000,
      sampleSize: 0.1,
      lastScreenshotImageDifferences: 0,
      screenCanvas: null,
      screenCtx: null,
      browserCanvas: null,
      browserCtx: null,
      lastGrabFrame: null,
      lastGrabFrameTime: 0,
      grabFrameThrottleMs: 500,
      screenDimensions: null,
      htmlRecordingInterval: null,
    }
  },
  computed: {
    uploadUrl() {
      return api.activeStorageDirectUploadUrl()
    },
    eventStorage() {
      return new EventStorage(this.quizzesAttemptSummary.quiz_id)
    },
    recordingIntervalMs() {
      return this.lowDelayBetweenFrames ? RECORDING_INTERVAL_MS : RECORDING_INTERVAL_SLOW_MS
    },
  },
  methods: {

    async startCapture() {
      try {
        this.stream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions)

        // Get video track and its settings
        const [videoTrack] = this.stream.getVideoTracks()
        const settings = videoTrack.getSettings()

        // Store screen dimensions for comparison
        this.screenDimensions = {
          width: settings.width,
          height: settings.height,
        }

        // Get display surface type - fallback for non-Firefox browsers
        this.displaySurface = settings.displaySurface

        // If displaySurface is not supported (Firefox), detect using dimensions
        if (!this.displaySurface) {
          this.displaySurface = this.detectScreenSurfaceType()
        }

        this.$emit("display-surface-changed", this.displaySurface)

        // Add screen change listener
        if ("getScreenDetails" in window) {
          const screenDetails = await window.getScreenDetails()
          this.screenChangeListener = () => this.updateScreenCount()
          screenDetails.addEventListener("screenschange", this.screenChangeListener)

          // Initial screen count
          this.updateScreenCount()
        } else {
          this.updateScreenCount()
        }

        // Start recording if it's enabled
        if (this.enableRecording) {
          this.startRecordingLoop()
        }

        this.$emit("screen-share-started")

        // Handle stream stop (user clicks "Stop sharing" in browser UI)
        this.stream.getVideoTracks()[0].addEventListener("ended", () => {
          this.stopCapture()
          this.$emit("update:enableScreenSharing", false)
          this.displaySurface = null
          this.$emit("display-surface-changed", null)
        })
      } catch (error) {
        console.error("Error starting screen capture:", error)
        if(error?.name === "NotAllowedError") {
          switch (error?.message) {
          case "Permission denied by system":
            this.$emit("screen-share-system-denied")
            break
          case "Permission denied.":
            this.$emit("screen-share-denied")
            break
          default:
            this.$emit("screen-share-denied", error.message)
          }
        }


        if(error?.message === "Permission denied by system" && error?.name === "NotAllowedError") this.$emit("screen-share-system-denied")
        if(error?.message === "Permission denied." && error?.name === "NotAllowedError") this.$emit("screen-share-denied")
        this.$emit("update:enableScreenSharing", false)
        this.displaySurface = null
        this.$emit("display-surface-changed", null)
        this.$emit("screen-share-stopped")
      }
    },

    detectScreenSurfaceType() {
      if (!this.screenDimensions) return null

      // Get window screen dimensions
      const screenWidth = window.screen.width
      const screenHeight = window.screen.height

      // Get the shared content dimensions
      const { width: sharedWidth, height: sharedHeight } = this.screenDimensions

      // Calculate ratios
      const screenRatio = screenWidth / screenHeight
      const sharedRatio = sharedWidth / sharedHeight

      // Check if dimensions match the screen dimensions (allowing for some scaling)
      const dimensionTolerance = 0.1 // 10% tolerance
      const ratioTolerance = 0.1

      const widthMatch = Math.abs(screenWidth - sharedWidth) / screenWidth < dimensionTolerance
      const heightMatch = Math.abs(screenHeight - sharedHeight) / screenHeight < dimensionTolerance
      const ratioMatch = Math.abs(screenRatio - sharedRatio) < ratioTolerance

      // If dimensions and ratio closely match the screen, it's likely a full screen share
      if ((widthMatch && heightMatch) || ratioMatch) {
        return "monitor"
      }

      // If dimensions are significantly smaller, it's likely a window or another screen
      return "window-or-multiscreens"
    },

    stopCapture() {
      if (this.stream) {
        this.stream.getTracks().forEach(track => track.stop())
        this.stream = null
      }

      // Remove screen change listener
      if (this.screenChangeListener) {
        window.getScreenDetails().then(screenDetails => {
          screenDetails.removeEventListener("screenschange", this.screenChangeListener)
          this.screenChangeListener = null
        })
      }

      this.stopRecordingLoop()
      this.displaySurface = null
      this.displayScreens = 0
      this.$emit("screen-share-stopped")
    },

    startRecordingLoop() {
      if (this.recordingInterval) return // Don't start if already running

      // Capture first image immediately
      this.captureScreenshot()

      this.recordingInterval = setInterval(() => {
        if (!this.stream) {
          this.stopRecordingLoop()
          return
        }
        this.captureScreenshot()
      }, this.recordingIntervalMs)

    },

    stopRecordingLoop() {
      if (this.recordingInterval) {
        clearInterval(this.recordingInterval)
        this.recordingInterval = null
      }
    },

    startHTMLRecordingLoop() {
      if (this.htmlRecordingInterval) return // Don't start if already running
      this.captureAndSaveBrowserCanvas()

      this.htmlRecordingInterval = setInterval(() => {
        this.captureAndSaveBrowserCanvas()
      }, this.recordingIntervalMs)
    },

    stopHTMLRecordingLoop() {
      if (this.htmlRecordingInterval) {
        clearInterval(this.htmlRecordingInterval)
        this.htmlRecordingInterval = null
      }
    },

    async detectOverlay() {
      if (!this.stream) return false

      // Throttle checks
      const now = Date.now()
      if (now - this.lastOverlayCheck < this.overlayCheckThrottleMs) {
        return false
      }
      this.lastOverlayCheck = now

      try {
        console.time("detectOverlay:total")
        // Update canvas dimensions if window size changed
        const width = window.innerWidth
        const height = window.innerHeight
        if (this.screenCanvas.width !== width || this.screenCanvas.height !== height) {
          this.screenCanvas.width = width
          this.screenCanvas.height = height
          this.browserCanvas.width = width
          this.browserCanvas.height = height
        }

        // Capture screen content first (faster than html2canvas)
        console.time("detectOverlay:grabFrame")
        let bitmap
        // Reuse last frame if within throttle time
        if (this.lastGrabFrame && (now - this.lastGrabFrameTime) < this.grabFrameThrottleMs) {
          bitmap = this.lastGrabFrame
        } else {
          const videoTrack = this.stream.getVideoTracks()[0]
          const imageCapture = new ImageCapture(videoTrack)
          bitmap = await imageCapture.grabFrame()
          this.lastGrabFrame = bitmap
          this.lastGrabFrameTime = now
        }
        console.timeEnd("detectOverlay:grabFrame")

        console.time("detectOverlay:drawScreen")
        // Scale the bitmap to match window size for direct comparison
        this.screenCtx.drawImage(bitmap, 0, 0, width, height)
        console.timeEnd("detectOverlay:drawScreen")

        // Capture browser content
        console.time("detectOverlay:html2canvas")
        const browserCanvas = await this.captureBrowserCanvas(width, height)
        console.timeEnd("detectOverlay:html2canvas")

        console.time("detectOverlay:copyCanvas")
        // Copy browser canvas to our reused canvas
        this.browserCtx.clearRect(0, 0, width, height)
        this.browserCtx.drawImage(browserCanvas, 0, 0)
        console.timeEnd("detectOverlay:copyCanvas")

        console.time("detectOverlay:getImageData")
        // Get image data for comparison
        const browserData = this.browserCtx.getImageData(0, 0, width, height)
        const screenData = this.screenCtx.getImageData(0, 0, width, height)
        console.timeEnd("detectOverlay:getImageData")

        console.time("detectOverlay:comparePixels")
        const sampleStep = Math.floor(1 / this.sampleSize)
        let differentPixels = 0
        let totalSampledPixels = 0

        // Compare RGB channels with tolerance
        const diffThreshold = 50 // Color difference threshold (0-255)
        for (let i = 0; i < browserData.data.length; i += 4 * sampleStep) {
          const rDiff = Math.abs(browserData.data[i] - screenData.data[i])
          const gDiff = Math.abs(browserData.data[i + 1] - screenData.data[i + 1])
          const bDiff = Math.abs(browserData.data[i + 2] - screenData.data[i + 2])

          // Consider a pixel different if any channel differs by more than the threshold
          if (rDiff > diffThreshold || gDiff > diffThreshold || bDiff > diffThreshold) {
            differentPixels++
          }
          totalSampledPixels++
        }
        console.timeEnd("detectOverlay:comparePixels")

        const diffPercentage = differentPixels / totalSampledPixels
        this.lastScreenshotImageDifferences = diffPercentage
        const hasOverlay = diffPercentage > 0.08 // 8% threshold

        if (this.debug) {
          console.log(`Image difference: ${(diffPercentage * 100).toFixed(2)}%`)
        }

        if (hasOverlay) {
          this.captureScreenshot(true)
        }

        console.timeEnd("detectOverlay:total")
        return hasOverlay
      } catch (error) {
        console.error("Error detecting overlay:", error)
        console.timeEnd("detectOverlay:total")
        return false
      }
    },

    startOverlayDetection() {
      if (this.overlayDetectionInterval) return // Don't start if already running

      this.overlayDetectionInterval = setInterval(() => {
        this.detectOverlay()
      }, 1000) // Check for overlays every second
    },

    stopOverlayDetection() {
      if (this.overlayDetectionInterval) {
        clearInterval(this.overlayDetectionInterval)
        this.overlayDetectionInterval = null
      }
    },

    async captureScreenshot(isOverlayTriggered = false) {
      if (!this.stream) return

      try {
        // Play debug beep
        if (this.debug) {
          const audioCtx = new (window.AudioContext || window.webkitAudioContext)()
          const oscillator = audioCtx.createOscillator()
          oscillator.type = "sine"
          oscillator.frequency.setValueAtTime(800, audioCtx.currentTime) // frequency in hertz
          oscillator.connect(audioCtx.destination)
          oscillator.start()
          oscillator.stop(audioCtx.currentTime + 0.1) // Duration: 100ms
        }

        const videoTrack = this.stream.getVideoTracks()[0]
        let bitmap

        // Check if ImageCapture is supported
        const isImageCaptureSupported = typeof ImageCapture === "function"

        if (isImageCaptureSupported) {
          // Use ImageCapture API
          const imageCapture = new ImageCapture(videoTrack)
          bitmap = await imageCapture.grabFrame()
        } else {
          // Fallback for browsers not supporting ImageCapture
          console.debug("ImageCapture not supported, using video element fallback")
          const video = this.$refs.video
          video.srcObject = new MediaStream([videoTrack])
          await new Promise((resolve) => {
            video.onloadedmetadata = () => {
              video.play()
              resolve()
            }
          })

          const canvas = document.createElement("canvas")
          canvas.width = video.videoWidth
          canvas.height = video.videoHeight
          const ctx = canvas.getContext("2d")
          ctx.drawImage(video, 0, 0)
          bitmap = canvas
        }

        const canvas = document.createElement("canvas")
        canvas.width = bitmap.width
        canvas.height = bitmap.height
        const context = canvas.getContext("2d")
        context.drawImage(bitmap, 0, 0)

        canvas.toBlob((blob) => {
          new Compressor(blob, {
            maxWidth: 1280,
            maxHeight: 1280,
            convertTypes: ["image/webp"],
            mimeType: "image/webp",
            strict: true,
            checkOrientation: false,
            success: async (compressedBlob) => {
              const screenshotTime = new Date()
              const extension = compressedBlob.type === "image/webp" ? "webp" : "jpg"
              const file = new File(
                [compressedBlob],
                `screenshot-${new Date().toISOString().replace(/[:.]/g, "-")}.${extension}`,
                { type: compressedBlob.type }
              )
              await this.saveScreenshot(file, screenshotTime, isOverlayTriggered)
              this.$emit("screenshot-captured", file, isOverlayTriggered)
            },
            error(err) {
              console.error("Compression failed:", err)
            },
          })
        }, "image/jpeg")
      } catch (error) {
        console.error("Error taking screenshot:", error)
      }
    },

    async saveScreenshot(file, screenshotTime, isOverlayTriggered) {
      await this.eventStorage.storeScreenshot({
        scoreId: this.quizzesAttemptSummary.id,
        quizId: this.quizzesAttemptSummary.quiz_id,
        happenedAt: screenshotTime,
        imageFile: file,
        details: { isOverlayTriggered },
      })
    },

    /**
     * Captures the browser canvas and saves it as a screenshot.
     * This method combines the functionality of captureBrowserCanvas and saveScreenshot.
     *
     * @param {boolean} [isOverlayTriggered=false] - Whether this capture was triggered by an overlay detection
     * @returns {Promise<void>}
     */
    async captureAndSaveBrowserCanvas() {
      // console.log("captureAndSaveBrowserCanvas")
      try {
        const width = window.innerWidth
        const height = window.innerHeight
        const canvas = await this.captureBrowserCanvas(width, height)

        canvas.toBlob((blob) => {
          new Compressor(blob, {
            maxWidth: 1280,
            maxHeight: 1280,
            convertTypes: ["image/webp"],
            mimeType: "image/webp",
            strict: true,
            checkOrientation: false,
            success: async (compressedBlob) => {
              const screenshotTime = new Date()
              const extension = compressedBlob.type === "image/webp" ? "webp" : "jpg"
              const file = new File(
                [compressedBlob],
                `screenshot-${new Date().toISOString().replace(/[:.]/g, "-")}.${extension}`,
                { type: compressedBlob.type }
              )
              await this.saveScreenshot(file, screenshotTime)
              this.$emit("screenshot-captured", file)
            },
            error(err) {
              console.error("Compression failed:", err)
            },
          })
        }, "image/jpeg")
      } catch (error) {
        console.error("Error capturing and saving browser canvas:", error)
      }
    },

    async syncEvents() {
      console.log("Start syncing events manually")
      new EventStorage(this.quizzesAttemptSummary.quiz_id).syncAllEvents()
        .then(() => {
          // console.log("Events synced")
          this.eventsDataSent = true
        })
        .catch((e)=>{
          console.log("Events sync failed", e)
        })
    },

    async updateScreenCount() {
      if ("getScreenDetails" in window) {
        const screenDetails = await window.getScreenDetails()
        this.displayScreens = screenDetails.screens.length
        this.$emit("screen-count-changed", this.displayScreens)
      } else {
        // TODO: detect screen count when getScreenDetails is not available
        this.displayScreens = 1
        this.$emit("screen-count-changed", this.displayScreens)
      }
    },

    /**
     * Captures the current browser window content using html2canvas with optimized settings.
     * This method creates a canvas representation of the current document while excluding
     * certain elements (video, canvas, iframes) and removing unnecessary attributes for better performance.
     *
     * @param {number} width - The width of the canvas to capture
     * @param {number} height - The height of the canvas to capture
     * @returns {Promise<HTMLCanvasElement>} A promise that resolves with the captured canvas element
     */
    async captureBrowserCanvas(width, height) {
      return html2canvas(document.documentElement, {
        useCORS: true,
        scale: 1,
        width,
        height,
        x: window.scrollX,
        y: window.scrollY,
        scrollX: 0,
        scrollY: 0,
        logging: false,
        backgroundColor: null,
        removeContainer: true,
        foreignObjectRendering: false, // Faster rendering
        allowTaint: true, // Allow cross-origin images
        ignoreElements: (element) => {
          // Ignore more elements for better performance
          return element.tagName === "VIDEO" ||
            element.tagName === "CANVAS" ||
            element.tagName === "IFRAME" ||
            element.classList.contains("ignore-overlay-detection") ||
            element.classList.contains("v-overlay") ||
            element.style.visibility === "hidden" ||
            element.style.display === "none" ||
            element.getAttribute("aria-hidden") === "true"
        },
        onclone: (clonedDoc) => {
          // Remove unnecessary elements from clone to improve performance
          const elementsToRemove = clonedDoc.querySelectorAll("script, link")
          elementsToRemove.forEach(el => el.remove())

          // Remove all event listeners and unnecessary attributes
          const allElements = clonedDoc.getElementsByTagName("*")
          for (const el of allElements) {
            el.removeAttribute("onclick")
            el.removeAttribute("onmouseover")
            el.removeAttribute("onmouseout")
            el.removeAttribute("onmouseenter")
            el.removeAttribute("onmouseleave")
          }
        },
      })
    },
  },
  watch: {
    enableScreenSharing: {
      immediate: true,
      async handler(newValue) {
        if (newValue) {
          await this.startCapture()
        } else {
          this.stopCapture()
        }
      },
    },
    enableRecording: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.startRecordingLoop()
        } else {
          this.stopRecordingLoop()
        }
      },
    },
    enableHtmlRecording: {
      immediate: true,
      handler(newValue) {
        // console.log("enableHTMLRecording", newValue)
        if (newValue) {
          this.startHTMLRecordingLoop()
        } else {
          this.stopHTMLRecordingLoop()
        }
      },
    },
    enableOverlayDetection: {
      immediate: true,
      handler(newValue) {
        // console.log("enableOverlayDetection", newValue)
        if (newValue) {
          this.startOverlayDetection()
        } else {
          this.stopOverlayDetection()
        }
      },
    },
  },
  mounted() {
    // Initialize canvases once
    this.screenCanvas = document.createElement("canvas")
    this.screenCtx = this.screenCanvas.getContext("2d", { willReadFrequently: true })
    this.browserCanvas = document.createElement("canvas")
    this.browserCtx = this.browserCanvas.getContext("2d", { willReadFrequently: true })
  },
  beforeDestroy() {
    this.stopCapture()
    // Clean up canvases
    this.screenCanvas = null
    this.screenCtx = null
    this.browserCanvas = null
    this.browserCtx = null
  },
}
</script>
