import { Extension } from "@tiptap/core"

const TAB_CHAR = "\u0009"

const IndentHandler = Extension.create({
  name: "indentHandler",
  addKeyboardShortcuts() {
    return {
      Tab: ({ editor }) => {
        const { selection } = editor.state
        const { $from, $to } = selection

        // Handle code block multi-line indentation
        if (editor.isActive("codeBlock")) {
          if (!selection.empty) {
            const lines = editor.state.doc.textBetween($from.pos, $to.pos).split("\n")
            let startPos = $from.pos - $from.parentOffset
            const expectedEndPos = $to.pos + lines.length // Increment endPos by the number of tabs added

            editor.chain()
              .command(({ tr }) => {
                let currentPos = startPos
                let offset = 0

                // Add tab to the start of each line
                lines.forEach((_, index) => {
                // Insert tab at the current line start
                  tr.insertText(TAB_CHAR, currentPos + offset)
                  offset += 1 // Increment offset for each tab inserted

                  if (index < lines.length - 1) {
                  // Calculate the length of current line including the line break
                    const lineLength = lines[index].length + 1
                    currentPos += lineLength
                  }
                })
                return true
              })
              // Update the selection to the expected end position
              .setTextSelection({
                from: $from.pos,
                to: expectedEndPos,
              })
              .run()
            return true
          }
        }

        // Check if we're at the start of a list item
        if (editor.isActive("listItem") && $from.parentOffset === 0) {
          // Attempt to sink the list item
          const sinkResult = editor.chain().sinkListItem("listItem").run()

          // If sinking was successful, return true
          if (sinkResult) {
            return true
          }
          // If sinking failed, we'll fall through to inserting a tab
        }

        // Insert a tab character
        editor
          .chain()
          .command(({ tr }) => {
            tr.insertText(TAB_CHAR)
            return true
          })
          .run()

        // Prevent default behavior (losing focus)
        return true
      },
      "Shift-Tab": ({ editor }) => {
        const { selection, doc } = editor.state
        const { $from, $to } = selection
        const pos = $from.pos

        // Handle code block multi-line unindentation
        if (editor.isActive("codeBlock")) {
          if (!selection.empty) {
            const lines = doc.textBetween($from.pos, $to.pos).split("\n")
            let startPos = $from.pos - $from.parentOffset

            editor.chain().command(({ tr }) => {
              let currentPos = startPos
              let offset = 0

              // Remove tab from the start of each line if it exists
              lines.forEach((_, index) => {
                const nextChar = tr.doc.textBetween(currentPos + offset, currentPos + offset + 1)
                if (nextChar === TAB_CHAR) {
                  tr.delete(currentPos + offset, currentPos + offset + 1)
                  offset -= 1 // Decrement offset for each tab removed
                }

                if (index < lines.length - 1) {
                  // Calculate the length of current line including the line break
                  const lineLength = lines[index].length + 1
                  currentPos += lineLength
                }
              })
              return true
            }).run()
            return true
          }
        }

        // Check if we're at the start of a list item
        if (editor.isActive("listItem") && $from.parentOffset === 0) {
          // If so, lift the list item
          return editor.chain().liftListItem("listItem").run()
        }

        // Check if the previous character is a tab
        if (doc.textBetween(pos - 1, pos) === TAB_CHAR) {
          // If so, delete it
          editor
            .chain()
            .command(({ tr }) => {
              tr.delete(pos - 1, pos)
              return true
            })
            .run()
          return true
        }

        // Prevent default behavior (losing focus)
        return true
      },

      // Handle code block multi-line indentation
      Enter: ({ editor }) => {
        if (editor.isActive("codeBlock")) {
          const { selection } = editor.state
          const { $from } = selection

          // Get the current line's content before the cursor
          const currentLine = ($from.nodeBefore?.textContent || "").split("\n").slice(-1)[0]
          // Extract the tabs at the start of the line ( using TAB_CHAR)
          const leadingTabs = currentLine.match(new RegExp(`^${TAB_CHAR}*`, "g"))[0]

          // Insert new line with the same indentation
          if (leadingTabs) {
            console.log("inserting", leadingTabs)
            editor.chain()
              .insertContent("\n" + TAB_CHAR.repeat(leadingTabs.length))
              .run()
            return true
          }
        }
        return false
      },
    }
  },
})

export default IndentHandler
