import { useGlobalFullscreen } from "@/composables/useGlobalFullscreen"
import { computed } from "vue"

export default {
  setup() {
    const { isFullscreen, enter, exit, toggle } = useGlobalFullscreen()

    return {
      fullscreen: computed(() => isFullscreen.value),
      toggle,
      enter,
      exit,
    }
  },
  methods: {
    goFullscreen() {
      if (this.fullscreen) { return }
      this.enter()
    },
    leaveFullscreen() {
      if (!this.fullscreen) { return }
      this.exit()
    },
  },
}
