<template>
  <div class="d-flex gap-1">
    <v-progress-circular
      indeterminate
      color="primary"
      size="21"
      v-if="pending && !hideScore"
    />
    <evalmee-chip
      :small="small"
      outlined
      label
      v-if="showTips"
      color="blue-grey"
      icon="mdi-debug-step-over"
    >
      {{ tipsLabel }}
    </evalmee-chip>
    <evalmee-chip
      :small="small"
      outlined
      label
      color="blue-grey"
      :icon="icon"
      v-tooltip="tooltip"
    >
      {{ label }}
    </evalmee-chip>
  </div>
</template>
<script>
import EvalmeeChip from "../shared/evalmee_chip.vue"

export default {
  name: "ChipPoints",
  components: { EvalmeeChip },
  props: {
    coefficient: { type: Number, required: true },
    score: { type: Number, required: false, default: null },
    small: { type: Boolean, default: true },
    hideScore: { type: Boolean, default: false },
    hasAnswer: { type: Boolean, default: true },
    isSkipped: { type: Boolean, default: false },
    pending: { type: Boolean, default: false },
    icon: { type: String, default: null },
    forExercise: { type: Boolean, default: false },
  },
  computed: {
    pointsRatio() {
      let roundedScore = Math.round(this.score * 100) / 100 || 0
      let roundedCoefficient = Math.round(this.coefficient * 100) / 100 || 0
      let questionScore = this.hideScore ? "" : `${ roundedScore } / `

      return `${questionScore}${ roundedCoefficient }`
    },
    label() {
      return `${this.pointsRatio} ${this.$tc("quiz.editor.questionCoefficients.points", this.coefficient)}`
    },
    showTips() {
      if( this.hideScore) return false
      return !this.hasAnswer || this.isSkipped
    },
    tipsLabel() {
      if(!this.hasAnswer) return this.$t("quiz.editor.questionCoefficients.notAnswered")
      if(this.isSkipped) return this.$t("quiz.editor.questionCoefficients.skipped")
      return ""
    },
    tooltip() {
      if(this.forExercise) return `${this.pointsRatio} ${this.$tc("quiz.editor.questionCoefficients.exercisePoints", this.coefficient)}`
    },
  },
}
</script>
